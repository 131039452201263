import { useEffect, useState } from "react";
import { Header } from "../components/headerTypeOne";
import { Constants, Step3QuestionIdConstants } from "../constants/textconstants";
import { Style } from "../styles/step1";
import { SaveAndCont } from "../components/saveandcont";
import { colors, Font } from "../styles/standards";
import { SurveyCompleted } from "../components/surveyCompleted";
import { useLocation, useNavigate } from "react-router-dom";
import { get, post } from "../config/api";
import { LoaderFaded } from "../components/loaderfaded";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../store/slices/util";
import { SaveProgress } from "../components/saveProgress";

const configuration = [
  {
    sectionHead:
      "Rank the following activities from most to least effective in influencing farmers' purchasing decisions.",
    qID: "1",
    question_id: Step3QuestionIdConstants.QUESTION_1,
    activeRank: 0,
    hasError: false,
    data: [
      {
        id: 1,
        rank:'',
        label: 'Awareness activities (Farmer Meetings, Training and Advisory)',
      },
      {
        id: 2,
        rank:'',
        label: 'Availability at nearest shop',
      },
      {
        id: 3,
        rank:'',
        label: 'Fellow farmers recommendation based on Product performance',
      },
      {
        id: 4,
        rank:'',
        label: 'Credit Facility at POS',
      },
    ],
    other: "",
  },
  {
    sectionHead:
      "Based on your experience, rank these activities interms of effectiveness in influencing retailer purchase decision.",
    qID: "2",
    question_id: Step3QuestionIdConstants.QUESTION_2,
    hasError: false,
    activeRank: 0,
    data: [
      {
        id: 1,
        rank: "",
        label: "Profit Margin",
      },
      {
        id: 2,
        rank: "",
        label: "Timely Availability and credit facility",
      },
      {
        id: 3,
        rank: "",
        label: "Field Activities (farmer meetings, one-to-one contact and demonstration trials)",
      },
      {
        id: 4,
        rank: "",
        label: "Advertising (TV, Radio, Newspapper, Youtube, Facebook)",
      },
    ],
    other: "",
  },
];

export const Step3 = () => {
  const [formData, setFormData] = useState({});
  const [config, setConfig] = useState(configuration);
  const [showSuccess, setShowSucess] = useState(false);
  const [saveProgresspop, setSaveProgress] = useState(false);
  const Utils = useSelector((state) => state.Utils);
  const [preference, setPreference] = useState({
    preference1: "",
    preference2: "",
  });

  const dispatch = useDispatch();

  const data = useLocation();

  const navigate = useNavigate();

  const validateRanks = () => {
    if (config) {
      return config.every((section) => {
        // Check if section.data exists before iterating
        if (!section.data) {
          return true; // Skip validation if data is absent
        }
    
        return section.data.every((item) => item.rank !== "");
      });
    }
  };
  const fetchSavedData = async () => {
    dispatch(setLoader(true));
    const mobilenumber = await localStorage.getItem("mobile_number");
    const response = await get(
      `/survey/responses?customer_id=${data.state.customerID}&page_number=page3&user_id=${data.state.user_id}&mobile_number=${mobilenumber}`
    );

    dispatch(setLoader(false));
    if (response && response.responses) {
     
      let retrievedData = response.responses;
      let existingConfig = config;

      existingConfig[0].data = retrievedData[0].response;
      let activeRanks = 0;
      retrievedData[0].response.map((item)=>{
        if(item.rank!==""){
          activeRanks++;
        }
      });
      existingConfig[0].activeRank = activeRanks;
      existingConfig[0].other = retrievedData[0].other;

      existingConfig[1].data = retrievedData[1].response;

      let activeRank1 = 0;
      retrievedData[1].response.map((item)=>{
        if(item.rank!==""){
          activeRank1++;
        }
      });
      existingConfig[1].activeRank = activeRank1;

      existingConfig[1].other = retrievedData[1].other;

      setPreference(retrievedData[2]);

      setConfig(existingConfig);
    } else {
      console.log("No Responses fetched");
    }
  };

  useEffect(() => {
    fetchSavedData();
  }, []);

  const submitResponse = async () => {
    let territoryID = await localStorage.getItem("territory_id");
    let userID = await localStorage.getItem("user_id");
    if (!validateRanks()) {
        console.error("Please rank all activities in both sections");
        window.alert("Please rank all fields in both sections");
        return; // Prevent submission if validation fails
    }
    dispatch(setLoader(true));

    // Map config data to the required structure
    let newstructuredData = config.map((item) => {
        return {
            other: item.other,
            question_id: item.question_id,
            sectionHead: item.sectionHead,
            response: item.data,
        };
    });

    // Add the new 3rd question structure
    const thirdQuestion = {
        qID: "3",
        question_id: Step3QuestionIdConstants.QUESTION_3,
        sectionHead: "What can be the most effective activities which can help to further increase your sale of Bayer products? Suggest two activities in order of your preference",
        response: {
            preference1: preference.preference1,
            preference2: preference.preference2,
        },
    };

    newstructuredData.push(thirdQuestion);

    const structured = {
      user_id: userID,
      page_number: "page3",
      responses: newstructuredData,
      action: "save",
      mobile_number: await localStorage.getItem("mobile_number"),
      customer_id: data.state.customerID,
    };

    post("/survey/save", structured)
        .then((response) => {
            // setShowSucess(true);
            dispatch(setLoader(false));
            navigate("/step4");
        })
        .catch((error) => {
            dispatch(setLoader(false));
            window.alert("Something went wrong!");
            console.error("Error fetching data:", error);
        });
  };

  const handleChange = (question_id, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [question_id]: value,
    }));
  };
  const setothers = (data, questionID) => {
    let newConfig = config;
    newConfig.forEach((section) => {
      if (section.qID === questionID) {
        section["other"] = data;
      }
    });

    setConfig(newConfig);
  };
  const toggleRank = (item, questionID) => {
    setConfig((prevConfig) => {
      const newConfig = prevConfig.slice(); // Create a copy to avoid mutation
      newConfig.forEach((section) => {
        if (section.qID === questionID) {
          section.data.forEach((data) => {
            if (data.id === item.id && item.rank === "") {
              let newRank = section.activeRank + 1;
              section.activeRank = newRank;
              data.rank = newRank;
            }
          });
        }
      });
      return newConfig;
    });
  };
  const resetSelection = (id) => {
    setConfig(
      (prevConfig) => {
        const newConfig = prevConfig.slice();
        newConfig.forEach((item) => {
          if (item.qID === id) {
            item.activeRank = 0;
            item.data.map((inner) => {
              inner.rank = "";
            });
          }
        });
        return newConfig;
      },
      () => {
        // call back
      }
    );
  };

  const saveProgress = async () => {
    dispatch(setLoader(true));
    let PostBody = config;
    PostBody.forEach((section) => {
      section["response"] = section.data;
      delete section.data;
    });
    PostBody.push(preference);
    const structured = {
      user_id: data.state.user_id,
      page_number: "page3",
      responses: PostBody,
      action: "save",
      customer_id: data.state.customerID,
      mobile_number: await localStorage.getItem("mobile_number"),
    };

    post("/survey/save", structured)
      .then((response) => {
        dispatch(setLoader(false));
        window.alert("Data Saved Successfully.");
        fetchSavedData();
      })
      .catch((error) => {
        dispatch(setLoader(false));
        console.error("Error fetching data:", error);
      });
    
      
  };
  const showSaveProgress = () => {
    setSaveProgress(true);
  };
  return (
    <div>
      {Utils.loading && <LoaderFaded />}
      {showSuccess && <SurveyCompleted />}
      {saveProgresspop && (
        <SaveProgress
          close={() => {
            setSaveProgress(false);
          }}
          saveprogress={async () => {
            let territoryID = await localStorage.getItem("territory_id");
            let userID = await localStorage.getItem("user_id");
            saveProgress();
            setSaveProgress(false);
            navigate(`/?territory_id=${territoryID}&fo_id=${userID}`);
          }}
        />
      )}
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.step3}
        showSaveProgress={showSaveProgress}
      />
      <div style={Style.container}>
        {config.map((section) => {
         
          return (
            <div>
             
              {(section.question_id=== Step3QuestionIdConstants.QUESTION_2 || section.question_id=== Step3QuestionIdConstants.QUESTION_1) && 
              
             <>
               <h4>
              {section.qID}. {section.sectionHead}
            </h4>
              <span
                style={{
                  display: "block",
                  marginBottom: 20,
                  ...Font.RobotoMedium,
                  fontSize: 12,
                }}
              >
                Select the activities to allot the rank, most effective activity
                should be selected first.
              </span>
              <span
                style={{
                  display: "block",
                  color: colors.Blue_Heilight,
                  marginBottom: 20,
                }}
                onClick={() => {
                  resetSelection(section.qID);
                }}
              >
                Reset selections
              </span>
             </> }
              
             
              {section &&
                section.data &&
                section.data.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        toggleRank(item, section.qID);
                      }}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          border: "1px solid #767676",
                          borderRadius: 5,
                          background:
                            item.rank !== ""
                              ? colors.Blue_Heilight
                              : colors.White,
                          color: item.rank !== "" ? colors.White : colors.White,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.rank}
                      </div>
                      <span
                        style={{
                          display: "block",
                          ...Font.RobotoMedium,
                          marginTop: 10,
                          marginLeft: 20,
                          width:'80%'
                        }}
                      >
                        {item.label}
                      </span>
                    </div>
                  );
                })}
                 {(section.question_id===Step3QuestionIdConstants.QUESTION_2 || section.question_id===Step3QuestionIdConstants.QUESTION_1) &&  <div>
                <span style={{ ...Font.RobotoMedium }}>
                  Others Please specify
                </span>
                <input
                  type="text"
                  onChange={(e) => {
                    setothers(e.target.value, section.qID);
                  }}
                  defaultValue={section.other}
                  style={{
                    boxSizing: "border-box",
                    display: "block",
                    width: "100%",
                    padding: 15,
                    marginTop: 6,
                    border: `1px solid ${colors.Grey_Dark}`,
                    borderRadius: 4,
                  }}
                />
              </div> }
             
            </div>
          );
        })}

        <h4>
          3. What can be the most effective activities which can help to further
          increase your sale of Bayer products, Suggest two activities in order
          of your preference
        </h4>
        <div>
          <span style={{ ...Font.RobotoMedium }}>Preference 1</span>
          <input
            type="text"
            onChange={(e) => {
              setPreference({ ...preference, preference1: e.target.value });
            }}
            value={preference.preference1}
            style={{
              boxSizing: "border-box",
              display: "block",
              width: "100%",
              padding: 15,
              marginTop: 6,
              border: `1px solid ${colors.Grey_Dark}`,
              borderRadius: 4,
            }}
          />
        </div>
        <div>
          <span style={{ ...Font.RobotoMedium }}>Preference 2</span>
          <input
            type="text"
            onChange={(e) => {
              setPreference({ ...preference, preference2: e.target.value });
            }}
            value={preference.preference2}
            style={{
              boxSizing: "border-box",
              display: "block",
              width: "100%",
              padding: 15,
              marginTop: 6,
              border: `1px solid ${colors.Grey_Dark}`,
              borderRadius: 4,
            }}
          />
        </div>
      </div>
      <SaveAndCont
        saveProgress={saveProgress}
        onContinue={() => {
          submitResponse();
        }}
      />
    </div>
  );
};
