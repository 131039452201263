export const companyNamesSeeds = [
  {"id": 1, "value": "Acsen Hyveg"},
  {"id": 2, "value": "Aditya Seeds"},
  {"id": 3, "value": "Advanta Seeds"},
  {"id": 4, "value": "Agri Tech"},
  {"id": 5, "value": "Ajit Seeds"},
  {"id": 6, "value": "Akash Seeds"},
  {"id": 7, "value": "Amar Biotech"},
  {"id": 8, "value": "Ankur seed"},
  {"id": 9, "value": "Apex"},
  {"id": 10, "value": "Aruti Seed"},
  {"id": 11, "value": "Arya Hybrid Seeds"},
  {"id": 12, "value": "Asian"},
  {"id": 13, "value": "Atash"},
  {"id": 14, "value": "Avani Seeds"},
  {"id": 15, "value": "Avirat Agri Seeds Private Limited"},
  {"id": 16, "value": "Awasthi Seeds"},
  {"id": 17, "value": "Baba Seed"},
  {"id": 18, "value": "Bajaj Agri Seeds"},
  {"id": 19, "value": "Zuari Seeds"},
  {"id": 20, "value": "Bhola Seed"},
  {"id": 21, "value": "Bio Seed"},
  {"id": 22, "value": "Biostadt"},
  {"id": 23, "value": "BioStar"},
  {"id": 24, "value": "Bisco Seed Tech"},
  {"id": 25, "value": "Chambal Fertilizers"},
  {"id": 26, "value": "Corteva"},
  {"id": 27, "value": "CP"},
  {"id": 28, "value": "Crystal Seeds"},
  {"id": 29, "value": "CT Seeds"},
  {"id": 30, "value": "Dantiwada Seeds"},
  {"id": 31, "value": "Dayal Seeds"},
  {"id": 32, "value": "Dcm Sri Ram Ltd"},
  {"id": 33, "value": "Delta Agri-genetics"},
  {"id": 34, "value": "Delta king Seed"},
  {"id": 35, "value": "DELTA SUDHA Seed"},
  {"id": 36, "value": "Dhanuka Seeds"},
  {"id": 37, "value": "Dhanya"},
  {"id": 38, "value": "Dhanya "},
  {"id": 39, "value": "Dinkar Seeds Pvt. Ltd.,"},
  {"id": 40, "value": "DSP Seeds"},
  {"id": 41, "value": "DVS"},
  {"id": 42, "value": "Fortune Seeds"},
  {"id": 43, "value": "Ganga Kaveri Seeds"},
  {"id": 44, "value": "Geo Seeds"},
  {"id": 45, "value": "GMS -H Seed"},
  {"id": 46, "value": "Godavari"},
  {"id": 47, "value": "Godrej Seeds & agrigenetics"},
  {"id": 48, "value": "Golden Seed"},
  {"id": 49, "value": "Goldking Biogene"},
  {"id": 50, "value": "Green Gold"},
  {"id": 51, "value": "Guha Seeds"},
  {"id": 52, "value": "Gujarat state seed corp."},
  {"id": 53, "value": "Gujrat Hybrid Seeds"},
  {"id": 54, "value": "Harlal Seed"},
  {"id": 55, "value": "Heera Seeds"},
  {"id": 56, "value": "Hi-Gene Seeds"},
  {"id": 57, "value": "HITECH"},
  {"id": 58, "value": "Hytech Seeds"},
  {"id": 59, "value": "HyVeg"},
  {"id": 60, "value": "Illegal"},
  {"id": 61, "value": "Indo Agri"},
  {"id": 62, "value": "Indo American"},
  {"id": 63, "value": "Indo- Gulf"},
  {"id": 64, "value": "Jagran Seeds"},
  {"id": 65, "value": "JK Agrigenetics"},
  {"id": 66, "value": "JK Seeds"},
  {"id": 67, "value": "Kamadgiri Seeds"},
  {"id": 68, "value": "Kanaha Seeds"},
  {"id": 69, "value": "Kanchan Ganga"},
  {"id": 70, "value": "Kanchan Seeds"},
  {"id": 71, "value": "Kaveri Seeds"},
  {"id": 72, "value": "Kirthimaan Agrigenetic"},
  {"id": 73, "value": "Kohinoor"},
  {"id": 74, "value": "Kribhco"},
  {"id": 75, "value": "Krishidhan"},
  {"id": 76, "value": "Krishna Seeds Agra"},
  {"id": 77, "value": "Krishna Seeds Kherli"},
  {"id": 78, "value": "Krishna_"},
  {"id": 79, "value": "Kuber seed"},
  {"id": 80, "value": "Limagrain"},
  {"id": 81, "value": "Long Pink"},
  {"id": 82, "value": "Luxmi"},
  {"id": 83, "value": "Mahabeej (MSSC)"},
  {"id": 84, "value": "Mahi Seeds"},
  {"id": 85, "value": "Mahindra Seeds"},
  {"id": 86, "value": "Mahodaya Seeds"},
  {"id": 87, "value": "Mahyco Pvt Ltd"},
  {"id": 88, "value": "Manak seeds"},
  {"id": 89, "value": "Mangalam Seeds"},
  {"id": 90, "value": "Manisha Biotech"},
  {"id": 91, "value": "Manomay Biosseeds Pvt Ltd"},
  {"id": 92, "value": "Maruti Seed Kherli"},
  {"id": 93, "value": "Marvel Seeds"},
  {"id": 94, "value": "Meera agro tech"},
  {"id": 95, "value": "Monsanto Seeds"},
  {"id": 96, "value": "Namdhari Seeds"},
  {"id": 97, "value": "Nath Bio-Genes Ltd"},
  {"id": 98, "value": "Navbharat Seeds"},
  {"id": 99, "value": "New Gene"},
  {"id": 100, "value": "New Nandi Seeds"},
  {"id": 101, "value": "New Ronak Seeds"},
  {"id": 102, "value": "Nidhi"},
  {"id": 103, "value": "Nirmal Seeds"},
  {"id": 104, "value": "Nitya Seeds"},
  {"id": 105, "value": "Nodai"},
  {"id": 106, "value": "NRL"},
  {"id": 107, "value": "NSC"},
  {"id": 108, "value": "Nusan Seeds"},
  {"id": 109, "value": "Nuziveedu Seeds Limited"},
  {"id": 110, "value": "NZ"},
  {"id": 111, "value": "Ompa Seed"},
  {"id": 112, "value": "Other"},
  {"id": 113, "value": "PAN "},
  {"id": 114, "value": "Panchaganga Seeds"},
  {"id": 115, "value": "Pareek seeds"},
  {"id": 116, "value": "Pioneer"},
  {"id": 117, "value": "Plant- Gene seeds"},
  {"id": 118, "value": "Prabhat Seeds"},
  {"id": 119, "value": "Pravardhan Seeds"},
  {"id": 120, "value": "Prithvi Hybrid Seeds"},
  {"id": 121, "value": "Priya seeds"},
  {"id": 122, "value": "Pure Gene Agritech"},
  {"id": 123, "value": "RajLaxmi Hybrid Seeds"},
  {"id": 124, "value": "Rallis"},
  {"id": 125, "value": "Rasi Seeds Pvt.Ltd"},
  {"id": 126, "value": "RJ Biotech"},
  {"id": 127, "value": "Rohini Seeds"},
  {"id": 128, "value": "RSSC"},
  {"id": 129, "value": "Safal Seeds"},
  {"id": 130, "value": "Sagar Seeds"},
  {"id": 131, "value": "Sandeep Seed"},
  {"id": 132, "value": "Satya Agri Tech"},
  {"id": 133, "value": "Savannah"},
  {"id": 134, "value": "Seedtech India"},
  {"id": 135, "value": "Seedworks International Pvt Ltd"},
  {"id": 136, "value": "Shakthi Seeds"},
  {"id": 137, "value": "Shaktiwardhak Seeds"},
  {"id": 138, "value": "Shankar Seeds"},
  {"id": 139, "value": "Shiv Seeds Bharatpur"},
  {"id": 140, "value": "Shree Ram Seeds"},
  {"id": 141, "value": "Shreeram Fertilizers_"},
  {"id": 142, "value": "Shrikhar"},
  {"id": 143, "value": "Siri Seeds"},
  {"id": 144, "value": "Solar"},
  {"id": 145, "value": "Sona Bio Seeds"},
  {"id": 146, "value": "Sona genetics"},
  {"id": 147, "value": "Sonam"},
  {"id": 148, "value": "Spriha"},
  {"id": 149, "value": "Sri Ram Bioseed_"},
  {"id": 150, "value": "Srikar"},
  {"id": 151, "value": "Sriram Fertilizer"},
  {"id": 152, "value": "Star Seeds"},
  {"id": 153, "value": "Super Seeds Hissar"},
  {"id": 154, "value": "Supper Seeds"},
  {"id": 155, "value": "Swati Seeds Pvt. Ltd.,"},
  {"id": 156, "value": "Syngenta"},
  {"id": 157, "value": "Tata"},
  {"id": 158, "value": "Tulsi "},
  {"id": 159, "value": "UPL Limited"},
  {"id": 160, "value": "Urmi"},
  {"id": 161, "value": "Veda Seeds"},
  {"id": 162, "value": "Vibha"},
  {"id": 163, "value": "Victory Seed"},
  {"id": 164, "value": "Vikram Seeds"},
  {"id": 165, "value": "Vishwas Seeds"},
  {"id": 166, "value": "VNR Seeds"},
  {"id": 167, "value": "Western Agri"},
  {"id": 168, "value": "Xylem Seeds"},
  {"id": 169, "value": "Yaganti Seeds"},
  {"id": 170, "value": "Yashoda Seeds"}
];

export const CompanyNamesCP = [
  {
    "id": 1,
    "value": "Adama"
  },
  {
    "id": 2,
    "value": "Atul"
  },
  {
    "id": 3,
    "value": "BASF"
  },
  {
    "id": 4,
    "value": "Zuari"
  },
  {
    "id": 5,
    "value": "Bharat Insecticides"
  },
  {
    "id": 6,
    "value": "Biostadt"
  },
  {
    "id": 7,
    "value": "Chambal Fertilizers"
  },
  {
    "id": 8,
    "value": "Coromondel Agri Co. & Fertilizers Ltd."
  },
  {
    "id": 9,
    "value": "Corteva"
  },
  {
    "id": 10,
    "value": "Crystal Phosphates"
  },
  {
    "id": 11,
    "value": "Dhanuka"
  },
  {
    "id": 12,
    "value": "Dharmaj Crop Guard Ltd."
  },
  {
    "id": 13,
    "value": "FMC"
  },
  {
    "id": 14,
    "value": "Fungicides India Ltd."
  },
  {
    "id": 15,
    "value": "Gharda"
  },
  {
    "id": 16,
    "value": "Godrej"
  },
  {
    "id": 17,
    "value": "Gujarat Super Phosphate"
  },
  {
    "id": 18,
    "value": "H.P.M."
  },
    {
    "id": 19,
    "value": "IFFCO-MC Crop Science"
  },
    {
    "id": 20,
    "value": "Indofil"
  },
    {
    "id": 21,
    "value": "Insecticides India Ltd"
  },
    {
    "id": 22,
    "value": "JU Agrisciences"
  },
    {
    "id": 23,
    "value": "Krishi Rasayan"
  },
    {
    "id": 24,
    "value": "Nagarjuna"
  },
    {
    "id": 25,
    "value": "Nichino india Pvt ltd"
  },
  {
    "id": 26,
    "value": "Others"
  },
  {
    "id": 27,
    "value": "PI Industries"
  },
  {
    "id": 28,
    "value": "Rallis"
  },
  {
    "id": 29,
    "value": "Shreeram Fertilizers"
  },
  {
    "id": 30,
    "value": "Sulphur Mills"
  },
  {
    "id": 31,
    "value": "Sumitomo"
  },
  {
    "id": 32,
    "value": "Swal Corp (part of UPL)"
  },
  {
    "id": 33,
    "value": "Syngenta"
  },
    {
    "id": 34,
    "value": "Tropical Agro"
  },
    {
    "id": 35,
    "value": "UPL"
  }
];


export const FieldsTobePrepopulated = ["CP_totalsale_section1_2023", "CP_totalsale_section1_2024", "Biologic_section1_2023", "Biologic_totalsale_section1_2024",
  "PGR_totalsale_section1_2023", "PGR_totalsale_section1_2024", "AllSeed_section1_2023", "AllSeed_totalsale_section1_2024", "solubleFertilizer_section1_2023",
  "solubleFertilizer_totalsale_section1_2024", "bulkFertilizer_section1_2023", "bulkFertilizer_totalsale_section1_2024", "company_top_nametop1total", "company_top_nametop2total",
  "company_top_nametop3total", "company_top_nametop4total", "company_top_nametop5total", "bayertotal2024", "retailsalesrice_RICE1_totalsale", "retailsalesrice_RICE2_totalsale",
  "retailsalesrice_RICE3_totalsale", 'bayerricetotal2024_BayerRICE_totalsale','corn_company_total_sale1', 'corn_company_total_sale2', 'corn_company_total_sale3',
  "corn_bayer_total_sale_2024"
]

export const MTFields = [
  "retailvolumerice_RICE1",
  "retailvolumerice_RICE2",
  "retailvolumerice_RICE3",
  "bayerricewretailvolume_BayerRICE",
  'corn_company_total_volume1',
  'corn_company_total_volume2',
  'corn_company_total_volume3',
  "corn_bayer_total_sale_mt"
]

export const FieldsWithACtualData = [
  "AllSeed_section1_2023",
  "AllSeed_totalsale_section1_2024",
  "Biologic_section1_2023",
  "Biologic_totalsale_section1_2024",
  "CP_totalsale_section1_2023",
  "CP_totalsale_section1_2024",
  "PGR_totalsale_section1_2023",
  "PGR_totalsale_section1_2024",
  "company_top_nametop1total",
  "company_top_nametop2total",
  "company_top_nametop3total",
  "company_top_nametop4total",
  "company_top_nametop5total",
  "retailsalesrice_RICE1_totalsale",
  "retailsalesrice_RICE2_totalsale",
  "retailsalesrice_RICE3_totalsale",
  "bayerricetotal2024_BayerRICE_totalsale",
  "solubleFertilizer_section1_2023",
  "solubleFertilizer_totalsale_section1_2024",
  "bulkFertilizer_section1_2023",
  "bulkFertilizer_totalsale_section1_2024",
  "calculated_totalsale_2023",
  "calculated_totalsale_2024",
  
]

export const croplist = [

  {
    "id": 2,
    "value": "Apple"
  },
  {
    "id": 3,
    "value": "Bajra/Millet"
  },
  {
    "id": 4,
    "value": "Banana"
  },
  {
    "id": 5,
    "value": "Bengal Gram"
  },
  {
    "id": 6,
    "value": "Betel Nut"
  },
  {
    "id": 7,
    "value": "Black & Green Gram"
  },
  {
    "id": 8,
    "value": "Brinjal"
  },
  {
    "id": 9,
    "value": "Cardamon"
  },
  {
    "id": 10,
    "value": "Carrot"
  },
  {
    "id": 11,
    "value": "Cashew"
  },
  {
    "id": 12,
    "value": "Castor"
  },
  {
    "id": 13,
    "value": "Chillies"
  },
  {
    "id": 14,
    "value": "Citrus"
  },
  {
    "id": 15,
    "value": "Cluster Bean"
  },
  {
    "id": 16,
    "value": "Coffee"
  },
  {
    "id": 17,
    "value": "Cole crop"
  },
  {
    "id": 18,
    "value": "Coriander"
  },
  {
    "id": 19,
    "value": "Cotton"
  },
  {
    "id": 20,
    "value": "Cucurbits"
  },
  {
    "id": 21,
    "value": "Cumin"
  },
  {
    "id": 22,
    "value": "Fennel"
  },
  {
    "id": 23,
    "value": "Field Bean"
  },
  {
    "id": 24,
    "value": "Floriculture"
  },
  {
    "id": 25,
    "value": "Ginger"
  },
  {
    "id": 26,
    "value": "Grapes"
  },
  {
    "id": 27,
    "value": "Groundnut"
  },
  {
    "id": 28,
    "value": "Jowar"
  },
  {
    "id": 29,
    "value": "Jute"
  },
  {
    "id": 30,
    "value": "Leguminous Crops"
  },
  {
    "id": 31,
    "value": "leguminous Vegetables"
  },
  {
    "id": 32,
    "value": "Maize/Corn"
  },
  {
    "id": 33,
    "value": "Mango"
  },
  {
    "id": 34,
    "value": "Mentha"
  },
  {
    "id": 35,
    "value": "Mustard"
  },
  {
    "id": 36,
    "value": "Okra"
  },
  {
    "id": 37,
    "value": "Onion"
  },
  {
    "id": 38,
    "value": "Other Crops"
  },
  {
    "id": 39,
    "value": "Other Fruits"
  },
  {
    "id": 40,
    "value": "Other Oilseeds"
  },
  {
    "id": 41,
    "value": "Other Vegetables"
  },
  {
    "id": 42,
    "value": "Paddy/Rice"
  },
  {
    "id": 43,
    "value": "Papaya"
  },
  {
    "id": 44,
    "value": "Pea"
  },
  {
    "id": 45,
    "value": "Peppermint"
  },
  {
    "id": 46,
    "value": "Pineapple"
  },
  {
    "id": 47,
    "value": "Pomegranate"
  },
  {
    "id": 48,
    "value": "Potato"
  },
  {
    "id": 49,
    "value": "Red Gram"
  },
  {
    "id": 50,
    "value": "Sesamum"
  },
  {
    "id": 51,
    "value": "Sorghum"
  },
  {
    "id": 52,
    "value": "Soybean"
  },
  {
    "id": 53,
    "value": "Sugarcane"
  },
  {
    "id": 54,
    "value": "Tea"
  },
  {
    "id": 55,
    "value": "Tobacco"
  },
  {
    "id": 56,
    "value": "Tomato"
  },
  {
    "id": 57,
    "value": "Turmeric"
  },
  {
    "id": 58,
    "value": "Watermelon/Musk melon"
  },
  {
    "id": 59,
    "value": "Wheat"
  }
]