import { Cordinates, PhotoController } from "../helper-functions/utils";
import { useEffect, useRef, useState } from "react";

import { get, post } from "../config/api";
import { Header } from "../components/headerTypeOne";
import { Gap } from "../styles/standards";
import { ActionButton } from "../components/button";
import { LoaderFaded } from "../components/loaderfaded";
import { useNavigate } from "react-router-dom";
import { SurveyCompleted } from "../components/surveyCompleted";
import {
  Constants,
  Step4QuestionIdConstants,
} from "../constants/textconstants";

export const Step4 = () => {
  const [image, setImage] = useState(null);
  const webcamRef = useRef(null);
  const [locationError, setLocationError] = useState(null);
  const [clickToCapture, setClickToCapture] = useState(false);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [showSuccess, setShowSucess] = useState(false);
 
  const navigate = useNavigate();

  const handleChange = async (identifier, value) => {
    setFormData((prev) => ({ ...prev, [identifier]: value }));
  };
  const getLocation = async (identifier) => {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          handleChange(identifier, { latitude, longitude });
          setLocationError("");
        },
        (error) => {
          setLocationError(
            "Please enable location access on your device and click below button!"
          );
        }
      );
    } else {
      setLocationError("Geolocation is not supported by this browser.");
    }
  };

  const capturePhoto = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);

      const filename = `${new Date().getTime()}.jpeg`; // Generate filename ONCE

      const response = await get(
        `/presigned-url?file_name=${filename}&content_type=image/jpeg`
      ); // Use the generated filename

      const blob = await fetch(imageSrc).then((res) => res.blob());
      const file = new File([blob], filename, { type: "image/jpeg" }); // Use the generated filename

      await fetch(response.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: file,
      });

      // Handle successful upload (e.g., show a success message)
      console.log("Image uploaded successfully!");
      // You can also store the CDN URL (response.cdn_url) in your state or database
      handleChange(Step4QuestionIdConstants.QUESTION_1, response.cdn_url);
    } catch (error) {
      console.error("Error capturing or uploading image:", error);
      // Handle error, e.g., display an error message to the user
    }
  };
  const submitSurvey = async () => {
    setLoader(true);

    let territoryID = await localStorage.getItem("territory_id");
    let userID = await localStorage.getItem("user_id");
    const customer_id_stored = await localStorage.getItem("customer_id");
    const restructured = [
      {
        question_id: Step4QuestionIdConstants.QUESTION_1,
        response: formData[Step4QuestionIdConstants.QUESTION_1],
      },
      {
        question_id: Step4QuestionIdConstants.QUESTION_2,
        response: formData[Step4QuestionIdConstants.QUESTION_2],
      },
    ];
    const structured = {
      user_id: userID,
      page_number: "page4",
      responses: restructured,
      action: "submit",
      customer_id: customer_id_stored,
      mobile_number: await localStorage.getItem("mobile_number"),
      territoryID: territoryID,
    };

    post("/survey/save", structured)
      .then((response) => {
        setShowSucess(true);
        setTimeout(() => {
          setShowSucess(false);
          navigate(`/?territory_id=${territoryID}&fo_id=${userID}`);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchSavedData = async () => {
    setLoader(true);
    let userID = await localStorage.getItem("user_id");
    const mobilenumber = await localStorage.getItem("mobile_number");
    const customer_id_stored = await localStorage.getItem("customer_id");
    const response = await get(
      `/survey/responses?customer_id=${customer_id_stored}&page_number=page4&user_id=${userID}&mobile_number=${mobilenumber}`
    );
    setLoader(false)
    if (response && response.responses && response.responses.length > 0) {
      setFormData({
        ...formData,
        shop_photo: response.responses[0].response,
        shop_location: response.responses[1].response,
      });
    } else {
      getLocation(Step4QuestionIdConstants.QUESTION_2);
    }
  };

  useEffect(() => {
    fetchSavedData();
  }, []);

  return (
    <div>
      {loader && <LoaderFaded />}
      <Header header={Constants.retailer_survey} subheader={Constants.step4} />
      {showSuccess && <SurveyCompleted />}
      <div style={{ padding: Gap.standardGap, marginBottom: 100 }}>
        <Cordinates
          identifier={Step4QuestionIdConstants.QUESTION_2}
          fieldName={"Geo cordinates"}
          getLocation={getLocation}
          formData={formData}
          locationError={locationError}
        />

        <PhotoController
          identifier={Step4QuestionIdConstants.QUESTION_1}
          fieldName={"Take Shop Photo"}
          image={image}
          clickToCapture={clickToCapture}
          webcamRef={webcamRef}
          formData={formData}
          capturePhoto={capturePhoto}
          setClickToCapture={setClickToCapture}
          setImage={setImage}
          isOptional={false}
        />
      </div>
      <div style={{ position: "fixed", bottom: 20, left: 20, right: 20 }}>
        <ActionButton
          onPress={() => {
            if (
              !formData[Step4QuestionIdConstants.QUESTION_1] ||
              formData[Step4QuestionIdConstants.QUESTION_1] == "" ||
              !formData[Step4QuestionIdConstants.QUESTION_2] ||
              formData[Step4QuestionIdConstants.QUESTION_2] == ""
            ) {
              window.alert("Both location and shop photo are mandatory");
            } else {
              submitSurvey();
            }
          }}
          text={"Submit Survey"}
          loader={false}
          isEnabled={true}
        />
      </div>
    </div>
  );
};
