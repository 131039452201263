import { useDispatch, useSelector } from "react-redux";
import { Styles } from "../styles/retailersList";
import { TextBoxWithIcon } from "./textBoxWithIcon";
import { useEffect, useState } from "react";
import { Constants } from "../constants/textconstants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { colors } from "../styles/standards";
import { NotAuthorized } from "./notAuthorized";
import { NoStores } from "./noSTorsfound";
import { fetchAllRetailers } from "../store/slices/retailersList";
import { resetFOTandSubDistrict } from "../store/slices/subDistricts";
import { Arrow, Arrow_Disabled } from "../constants";

const filterconfig = [
  {
    label: "all",
    isactive: true,
    count: 0
  },
  {
    label: "open",
    isactive: false,
    count: 0
  },
  {
    label: "in_progress",
    isactive: false,
    count: 0
  },
  {
    label: "closed",
    isactive: false,
    count: 0
  },
];

const surveyStatuses = {
  inprogress: "in_progress",
  completed: "closed",
};

const RetailerRow = ({ retailer, setPredefinedFilter }) => {
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams.entries());

  let parsedParameters = {
    territory_id: queryParams.territory_id,
    user_id: queryParams.fo_id,
    fo_phone_number: queryParams.fo_phone_number,
  };

  const navigate = useNavigate();

  const reDirectToOtp = (data) => {
    setPredefinedFilter('all');
    localStorage.setItem('mobile_number', retailer.mobile_number);
    localStorage.setItem('customer_id', retailer.customer_id);
    navigate("/otp", { state: { retailer: data, parsedParameters } });
  };

  const rowStyles = {
    ...Styles.retailerRow,
    pointerEvents: retailer.fo_id === parsedParameters.user_id ? "all" : "none",
  };

  return (
    <div
      style={retailer.fo_id ? rowStyles : Styles.retailerRow}
      onClick={async () => {
        if (
          retailer.survey_status &&
          retailer.survey_status !== surveyStatuses.completed
        ) {
         
         
          if (
            retailer.survey_status === surveyStatuses.inprogress
          ) {
            await localStorage.setItem('mobile_number', retailer.mobile_number);
            await localStorage.setItem('brp_mobile_number', retailer.mobile_number);

            await localStorage.setItem('customer_id', retailer.customer_id);
            await localStorage.setItem('createnewuser', "false");
            navigate("/step1", {
              state: {
                isNewUser: true,
                retailer: retailer,
                parsedParameters: parsedParameters,
                territoryID: parsedParameters.territory_id,
                userID: parsedParameters.user_id? parsedParameters.user_id: queryParams.fo_id,
              },
            });
          } else {
           
            reDirectToOtp(retailer);
          }
        }
      }}
    >
      <div style={Styles.arrowContainer}>
        <span style={Styles.retailerName}>{retailer.shop_name}</span>
        {retailer.survey_status &&
        retailer.survey_status === surveyStatuses.completed ? (
          <img
            src={Arrow_Disabled}
            style={{ width: 30, height: 30 }}
            alt="loader"
          />
        ) : retailer.fo_id !== parsedParameters.user_id &&
          retailer.survey_status === surveyStatuses.inprogress ? (
          <img
            src={Arrow_Disabled}
            style={{ width: 30, height: 30 }}
            alt="loader"
          />
        ) : (
          <img src={Arrow} style={{ width: 30, height: 30 }} alt="loader" />
        )}
      </div>

      <span style={Styles.area}>TER: {retailer.territory}, FOT: {retailer.fot}</span>
      <span style={Styles.phoneNumber}>{retailer.mobile_number}</span>
      {(retailer.survey_status === surveyStatuses.inprogress ||
        retailer.survey_status === surveyStatuses.completed) && (
        <span
          style={{
            ...Styles.statusCapsule,
            background:
              retailer.survey_status === surveyStatuses.inprogress
                ? "#49c3f6"
                : retailer.survey_status === surveyStatuses.completed
                ? "#efefef"
                : "",
            color:
              retailer.survey_status === surveyStatuses.inprogress
                ? "#2585c3"
                : retailer.survey_status === surveyStatuses.completed
                ? "#767676"
                : "",
          }}
        >
          Survey {retailer.survey_status}
        </span>
      )}
    </div>
  );
};

export const RetailersList = ({ parsedParameters }) => {
  const retailers = useSelector((state) => state.retailers);
  const fot = useSelector((state) => state.subDist);
  const [filtered, setFiltered] = useState([]);
  const [surveyFilter, setSurveyFilter] = useState(filterconfig);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fot.selectedFOTvalue) {
      setFiltered(
        retailers.retailers.filter((item) => item.fot_id == fot.selectedFOTvalue)
      );
    }
  }, [fot.selectedFOTvalue]);

  useEffect(() => {
    if (fot.selectedSubDist) {
      setFiltered(
        retailers.retailers.filter(
          (item) =>
            item.sub_district == fot.selectedSubDist &&
            item.fot_id == fot.selectedFOTvalue
        )
      );
    }
  }, [fot.selectedSubDist]);

  const getStatusCount = () => {
    let surveyFiltercopy = [...surveyFilter];
    surveyFiltercopy[1].count = 0;
    surveyFiltercopy[2].count = 0;
    surveyFiltercopy[3].count = 0;
    retailers.retailers.forEach(element => {
      if(element.survey_status == 'open'){
        surveyFiltercopy[1].count = parseInt(surveyFiltercopy[1].count)+1;
      }else if(element.survey_status == 'in_progress'){
        surveyFiltercopy[2].count+=1;
      }else if(element.survey_status == 'closed'){
        surveyFiltercopy[3].count+=1;
      }
    });
    setSurveyFilter(surveyFiltercopy)
  }

  useEffect(() => {
    let copiedOBJ = [...retailers.retailers];
    let data = copiedOBJ.sort((a, b) => {
      
      if(a && a.shop_name && b && b.shop_name){
        return a.shop_name.toLowerCase().localeCompare(b.shop_name.toLowerCase());
      }
      
    });
    setFiltered(data);
    getStatusCount();
  }, [retailers]);

  const onChange = (data) => {
    dispatch(resetFOTandSubDistrict());
    if (data === "") {
      setFiltered(retailers.retailers);
    } else {
      setFiltered(
        retailers.retailers.filter(
          (item) =>
            (item.shop_name && item.shop_name.toLowerCase().includes(data)) ||
            (item.mobile_number &&
              item.mobile_number.toLowerCase().includes(data)) ||
            (item.territory && item.territory.toLowerCase().includes(data))
        )
      );
    }
  };

  const setPredefinedFilter = (status) => {
    if (status === "all") {
      // eslint-disable-next-line array-callback-return
      filterconfig.map((item) => {
        if (item.label === status) {
          item.isactive = true;
        } else {
          item.isactive = false;
        }
      });

      let copiedOBJ = [...retailers.retailers];
      let data = copiedOBJ.sort((a, b) => {
        
        if(a && a.shop_name && b && b.shop_name){
          return a.shop_name.toLowerCase().localeCompare(b.shop_name.toLowerCase());
        }
        
      });

      
      setFiltered(data);
    } else {
      // eslint-disable-next-line array-callback-return
      filterconfig.map((item) => {
        if (item.label === status) {
          item.isactive = true;
        } else {
          item.isactive = false;
        }
      });
      let copiedOBJ = [...retailers.retailers];
      let data = copiedOBJ.sort((a, b) => {
        
        if(a && a.shop_name && b && b.shop_name){
          return a.shop_name.toLowerCase().localeCompare(b.shop_name.toLowerCase());
        }
        
      });
      


      setFiltered(
        data.filter((item) => item.survey_status === status)
      );
    }
    setSurveyFilter(filterconfig);
  };

  return (
    <div style={Styles.container}>
      {retailers.length == 0 && <NoStores />}
      <div style={Styles.searchContainer}>
        <TextBoxWithIcon
          iconname={"fa fa-search"}
          handler={onChange}
          placeHolder={Constants.search_by_name_number}
        />
        <div style={Styles.filterCont}>
          {surveyFilter.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setPredefinedFilter(item.label);
                }}
                style={{
                  ...Styles.filterCapsule,
                  background: item.isactive ? colors.Blue_Dark : colors.White,
                  color: item.isactive ? colors.White : colors.Black,
                  width: "20%",
                  textTransform: "capitalize",
                }}
              >
                {item.label}
                {item.count !==0 && <span style={Styles.counts}>
                  {item.count}
                </span>}
                
              </div>
            );
          })}
        </div>
        <span style={Styles.totalCount}>
          Total count: {retailers.retailers.length}
        </span>
        <span
          style={Styles.syncnow}
          onClick={() => {
            setPredefinedFilter('all')
            dispatch(fetchAllRetailers(parsedParameters));
          }}
        >
          Sync Now
        </span>
      </div>
      {filtered.map((retailer, i) => {
        return <RetailerRow retailer={retailer} setPredefinedFilter={setPredefinedFilter} key={i} />;
      })}
    </div>
  );
};
