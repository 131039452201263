import { Percentage } from "../constants";
import { colors, Font, Gap } from "./standards";

export const Style = {
  container: {
    padding: Gap.standardGap,
    paddingBottom: 100,
  },
  checkboxContain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    background: colors.Grey,
    padding: 20,
  },
  sectionSeperator: {
    width: "100%",
    height: 15,
    background: colors.Grey,
    marginTop: 20,
  },
  fieldElements: {
    boxSizing: "border-box",
    display: "block",
    width: "100%",
    padding: 15,
    marginTop: 6,
    border: `1px solid ${colors.Grey_Dark}`,
    borderRadius: 4,
  },
  fieldElementsPer: {
    boxSizing: "border-box",
    display: "block",
    width: "100%",
    padding: 15,
    marginTop: 6,
    border: `1px solid ${colors.Grey_Dark}`,
    borderRadius: 4,
    backgroundImage: `url(${Percentage})`,
    backgroundSize: 22,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 11px",
  },
  fieldName: {
    ...Font.RobotoMedium,
    fontSize: 14,
    color: colors.Blue_Dark,
    display: "block",
    marginBottom: 10,
  },
  fieldContainer: {
    padding: "10px 0px",
  },
  subhead: {
    ...Font.RobotoBold,
    fontSize: 14,
    color: colors.Blue_Heilight,
    display: "block",
    marginBottom: 10,
  },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
  },
  radiorow: {
    ...Font.RobotoMedium,
    fontSize: 14,
    color: colors.Blue_Dark,
    display: "block",
    marginTop: 10,
  },
  camera: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
    height: 150,
    border: `2px dashed ${colors.Blue_Heilight}`,
    background: "#95caff",
    marginTop: 20,
    position: "relative",
  },
  toggleContainer: {
    display: "flex",
    padding: "20px 10px",
    background: "#deebf7",
    flexDirection: "row",
  },
  Totalsale: {
    display: "block",
    ...Font.RobotoBold,
    fontSize: 14,
    color: colors.Black,
    marginBottom: 10,
  },
  toggleText: {
    ...Font.RobotoMedium,
    display: "block",
    padding: 8,
  },
  wrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: "rgba(255,255,255,0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 30,
    zIndex: 999,
  },
  infoText:{
    fontSize:10,
    display: 'block',
    marginBottom: 15,
    color: '#ff9200'
  }
};
