import { useEffect, useState } from "react";
import { Header } from "../components/headerTypeOne";
import {
  Constants,
  Step2QuestionIdConstants,
} from "../constants/textconstants";
import { clearDataArray, clearDataArraySEEDS, clearDataArraySEEDSCorn, clearDataArraySEEDSRice, config, TempNonMandatory, TempNonMandatoryPaddy, TempNonMandatorySeeds } from "../config/step2";
import { Style } from "../styles/step1";
import { SaveAndCont } from "../components/saveandcont";
import { useLocation, useNavigate } from "react-router-dom";
import TextInputWithUnit from "../components/textInputwithUnit";
import { colors, Font } from "../styles/standards";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, showToast } from "../store/slices/util";
import { SingleSelect } from "../helper-functions/utils";
import { ProceedNotification } from "../components/proceedNotify";
import { get, post } from "../config/api";
import { restructureAnswers, structureData } from "../helper-functions";
import { SaveProgress } from "../components/saveProgress";
import { InfoPopUp } from "../components/infoPopuop";
import { FieldsTobePrepopulated, MTFields } from "../config/statesanddistricts";
import { LoaderFaded } from "../components/loaderfaded";
import { SummaryPopUp } from "../components/summaryPopup";
import { updateCPRelatedFields } from "../helper-functions/calculations";
import { cornKeywords, updateCornData } from "../helper-functions/cornHelper";
import {
  calculateTotalRetailRiceVolume,
  safeParseInt,
} from "../helper-functions/step2helper";
import { formatNumberToUnits } from "../helper-functions/utils";
import { Checked, Unchecked } from "../constants";

const calculatePercentageReduction = (totalValue, reductionValue) => {
  const percentageReduction = (reductionValue / totalValue) * 100;
  const remainingPercentage = 0 + percentageReduction;
  return remainingPercentage;
};
// HighlightBar Component
const HeighlightBar = ({ typeOfsale, value }) => {
  let dataKeys = [];
  if (typeOfsale === "Crop Protection") {
    dataKeys = ["insectiside", "fungiside", "herbiside", "seedtreatment"];
  } else if (typeOfsale === "Rice(Hybrid Seed)") {
    dataKeys = [
      "retailsalesrice_RICE3",
      "retailsalesrice_RICE2",
      "retailsalesrice_RICE1",
      "bayerricetotal2024_BayerRICE",
    ];
  } else {
    dataKeys = [
      "SEE_cornhybrid",
      "SEE_paddyybrid",
      "SEE_vegetablehybrid",
      "SEE_otherhybrid",
    ];
  }

  const totalSales = dataKeys.reduce(
    (sum, key) => sum + (value[`${key}_totalsale`] ?? 0),
    0
  );

  let total2024Key = "";
  if (typeOfsale === "Crop Protection") {
    total2024Key = "CP_totalsale_section1_2024";
  } else if (typeOfsale === "Rice(Hybrid Seed)") {
    total2024Key = "SEE_paddyybrid_retail";
  } else {
    total2024Key = "AllSeed_totalsale_section1_2024";
  }

  const total2024 = value[total2024Key] ?? 0;

  let percentageofRice = calculatePercentageReduction(total2024, totalSales)
    ? calculatePercentageReduction(total2024, totalSales)
    : 0.0;

  let colorChecker =
    typeOfsale === "Rice(Hybrid Seed)"
      ? percentageofRice > 100
      : 100 - totalSales < 0;

  let enteredTotalWholesale = 0;
  let aggregated = 0;
  if (
    value.AllSeed_section1_wholesalepercentage &&
    value.AllSeed_section1_wholesalepercentage !== 0
  ) {
    enteredTotalWholesale = (
      (value.AllSeed_section1_wholesalepercentage / 100) *
      value.AllSeed_totalsale_section1_2024
    ).toFixed(2);
    let cornHybrid =
      value["SEE_cornhybrid_retail"] *
      (value["cornhybrid_wholesalepercentage"] / 100);

    let paddyHybrid =
      value["SEE_paddyybrid_retail"] *
      (value["paddyybrid_wholesalepercentage"] / 100);

    let veggyHybrid =
      value["SEE_vegetablehybrid_retail"] *
      (value["vegetablehybrid_wholesalepercentage"] / 100);

    let otherHybrid =
      value["SEE_otherhybrid_retail"] *
      (value["otherhybrid_wholesalepercentage"] / 100);

    aggregated =
      (cornHybrid ? cornHybrid : 0) +
      (paddyHybrid ? paddyHybrid : 0) +
      (veggyHybrid ? veggyHybrid : 0) +
      (otherHybrid ? otherHybrid : 0);
  }

  return (
    <div
      style={{
        background: colorChecker ? "#ffa4a4" : "#e1f2e1",
        padding: 20,
        marginBottom: 20,
        position: "sticky",
        top: 0,
        zIndex: 9,
      }}
    >
      <span style={Style.Totalsale}>Total {typeOfsale} sale in 2024</span>
      <span>
        ₹{formatNumberToUnits(parseInt(total2024))}{" "}
        {typeOfsale === "Rice(Hybrid Seed)"
          ? "(" + percentageofRice.toFixed(2) + "%)"
          : "(" + (0 + totalSales).toFixed(2) + "%)"}
      </span>
      {total2024Key === "AllSeed_totalsale_section1_2024" && (
        <span
          style={{
            display: "block",
            padding: "20px 0px",
            background: enteredTotalWholesale < aggregated ? "red" : "#e1f2e1",
          }}
        >
          {enteredTotalWholesale !== 0 && (
            <span>
              Total Wholesale Seed Sales: ₹
              {formatNumberToUnits(parseInt(enteredTotalWholesale))},
              Category-Wise total Wholesale Sales: ₹
              {formatNumberToUnits(parseInt(aggregated))}
            </span>
          )}
        </span>
      )}

      {colorChecker && (
        <span style={{ ...Font.RobotoMedium, fontSize: 13, display: "block" }}>
          Total sales % cant be more than 100% of {typeOfsale} sales
        </span>
      )}
    </div>
  );
};

export const Step2 = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fieldErrors, setFieldErrors] = useState({});
  const [reviewInfo, setReviewInfo] = useState(false);
  const data = useLocation();
  const [saveProgress, setSaveProgress] = useState(false);
  const [showError, setShowError] = useState(false);
  const [dataToPrepopulate, setDataToPrepopulate] = useState({});
  const [showSummaryPOP, setshowSummaryPOP] = useState(false);
  const [errorSummary, setErrorSummary] = useState([]);
  const [withGST, setWithGST] = useState(false);
  const Utils = useSelector((state) => state.Utils);

  const calculateWholeSale = (updatedFormData) => {
    let cornHybrid = parseInt(
      updatedFormData["SEE_cornhybrid_retail"] *
        (updatedFormData["cornhybrid_wholesalepercentage"] / 100)
    )
      ? parseInt(
          updatedFormData["SEE_cornhybrid_retail"] *
            (updatedFormData["cornhybrid_wholesalepercentage"] / 100)
        )
      : 0;

    let paddyHybrid = parseInt(
      updatedFormData["SEE_paddyybrid_retail"] *
        (updatedFormData["paddyybrid_wholesalepercentage"] / 100)
    )
      ? parseInt(
          updatedFormData["SEE_paddyybrid_retail"] *
            (updatedFormData["paddyybrid_wholesalepercentage"] / 100)
        )
      : 0;

    let veggyHybrid = parseInt(
      updatedFormData["SEE_vegetablehybrid_retail"] *
        (updatedFormData["vegetablehybrid_wholesalepercentage"] / 100)
    )
      ? parseInt(
          updatedFormData["SEE_vegetablehybrid_retail"] *
            (updatedFormData["vegetablehybrid_wholesalepercentage"] / 100)
        )
      : 0;

    let otherHybrid = parseInt(
      updatedFormData["SEE_otherhybrid_retail"] *
        (updatedFormData["otherhybrid_wholesalepercentage"] / 100)
    )
      ? parseInt(
          updatedFormData["SEE_otherhybrid_retail"] *
            (updatedFormData["otherhybrid_wholesalepercentage"] / 100)
        )
      : 0;
    return (
      ((cornHybrid + paddyHybrid + veggyHybrid + otherHybrid) /
        updatedFormData["AllSeed_totalsale_section1_2024"]) *
      100
    );
  };

  const fetchSavedData = async () => {
    dispatch(setLoader(true));
    const fieldsToBeDevided = FieldsTobePrepopulated;
    const mobilenumber = await localStorage.getItem("mobile_number");
    get(
      `/survey/responses?customer_id=${data.state.customerID}&page_number=page2&user_id=${data.state.user_id}&mobile_number=${mobilenumber}`
    ).then((response) => {
      if (response && response.responses) {
        const Prepopulate = response.responses;
        let dataToPrepopulate = {};
        let formData = {};
        Prepopulate.forEach((item) => {
          const responseItem = item.response;

          Object.keys(responseItem).forEach((keys) => {
            formData[keys] = responseItem[keys];
            if (fieldsToBeDevided.includes(keys)) {
              responseItem[keys] = responseItem[keys] / 100000;
            }
            if (MTFields.includes(keys)) {
              responseItem[keys] = responseItem[keys];
            }
          });
          console.log(item.question_id);
          if (item.question_id === Step2QuestionIdConstants.IS_GST_INCLUDED) {
            console.log(
              `Prepopulating isGSTincluded with value:`,
              responseItem
            );
            setWithGST(responseItem);
          }
          dataToPrepopulate = { ...dataToPrepopulate, ...responseItem };
        });

        setDataToPrepopulate(dataToPrepopulate);
        setFormData(formData);
        setTimeout(() => {
          dispatch(setLoader(false));
        }, 1000);
      }
    });
    dispatch(setLoader(false));
  };

  useEffect(() => {}, [dataToPrepopulate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSavedData();
  }, []);

  useEffect(() => {
    dispatch(
      showToast({ toast: true, toastmessage: "Progress saved successfully!" })
    );
    setTimeout(() => {
      dispatch(showToast({ toast: false, toastmessage: "" }));
    }, 1500);
    // **New: Save progress every 30 seconds**
    const intervalId = setInterval(async () => {}, 30000); // Every 30 seconds

    return () => clearInterval(intervalId);
  }, [data.state, saveProgress]);

  const findTotal = (data) => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue ? currentValue : 0);
    }, 0);
  };
  const FindSection1WholesalePercentage = (updatedFormData) => {
    let spPercentage =
      (updatedFormData["CP_section1_wholesalepercentage"] / 100) *
      updatedFormData["CP_totalsale_section1_2024"];

    let BiologicPercentage =
      (updatedFormData["Biologic_section1_wholesalepercentage"] / 100) *
      updatedFormData["Biologic_totalsale_section1_2024"];

    let PGRPercentage =
      (updatedFormData["PGR_section1_wholesalepercentage"] / 100) *
      updatedFormData["PGR_totalsale_section1_2024"];
    let AllSeedPercentage =
      (updatedFormData["AllSeed_section1_wholesalepercentage"] / 100) *
      updatedFormData["AllSeed_totalsale_section1_2024"];
    let FertPercentage =
      (updatedFormData["solubleFertilizer_section1_wholesalepercentage"] /
        100) *
      updatedFormData["solubleFertilizer_totalsale_section1_2024"];
    let bulkFertPercentage =
      (updatedFormData["bulkFertilizer_section1_wholesalepercentage"] / 100) *
      updatedFormData["bulkFertilizer_totalsale_section1_2024"];

    let total = findTotal([
      spPercentage,
      BiologicPercentage,
      PGRPercentage,
      AllSeedPercentage,
      FertPercentage,
      bulkFertPercentage,
    ]);

    return parseFloat(
      updatedFormData["calculated_totalsale_2024"]
        ? (
            (total / updatedFormData["calculated_totalsale_2024"]) *
            100
          ).toFixed(2)
        : ""
    );
  };
  const handleChange = async (identifier, value, unit) => {
    let updatedFormData = {};
    let updatedDataToPrepopulate = { ...dataToPrepopulate };

    if (typeof value === "object") {
      updatedDataToPrepopulate[identifier] = value.value;
      updatedFormData = { ...formData, [identifier]: value.value };
    } else {
      if (value.toString().startsWith("0") && value.length > 1) {
        value = value.substring(1);
      }
      if (FieldsTobePrepopulated.includes(identifier)) {
        if (unit == "lakhs") {
          if (value === 0) {
            updatedDataToPrepopulate[identifier] = 0;
          } else {
            updatedDataToPrepopulate[identifier] = value / 100000;
          }
        } else if (unit == "crores") {
          if (value === 0) {
            updatedDataToPrepopulate[identifier] = 0;
          } else {
            updatedDataToPrepopulate[identifier] = value / 10000000;
          }
        }
      } else if (MTFields.includes(identifier)) {
        if (unit === "mt") {
          updatedDataToPrepopulate[identifier] = value / 1000;
        } else {
          updatedDataToPrepopulate[identifier] = value;
        }
      } else {
        updatedDataToPrepopulate[identifier] = value;
      }
      updatedFormData = { ...formData, [identifier]: value };
    }

    const calculateFieldSum = (fieldKey, resultKey) => {
      const sum = Object.keys(updatedFormData).reduce(
        (acc, key) =>
          key.includes(fieldKey) ? acc + (updatedFormData[key] ?? 0) : acc,
        0
      );
      updatedFormData[resultKey] = sum;
      updatedDataToPrepopulate[resultKey] = sum;
    };

    if (identifier.includes("section1_2023")) {
      calculateFieldSum("section1_2023", "calculated_totalsale_2023");
    } else if (identifier.includes("section1_2024")) {

      if (identifier == "CP_totalsale_section1_2024") {
        if(value === 0){
          clearDataArray.map((clearIdentifier)=>{
            updatedDataToPrepopulate[clearIdentifier] = 0;
            updatedFormData[clearIdentifier] = 0;
            formData[clearIdentifier] = 0;
          });
        }

        const data = await updateCPRelatedFields(formData, value);
        if (Object.keys(data).length > 0) {
          Object.keys(data).forEach((updated) => {
            updatedDataToPrepopulate[updated] = data[updated];
            updatedFormData[updated] = data[updated];
          });
        }
      }
      

      if (identifier == "AllSeed_totalsale_section1_2024" ) {
       
        if(value === 0){
          console.log("CAME INSIDE ")
          clearDataArraySEEDS.map((clearIdentifier)=>{
            updatedDataToPrepopulate[clearIdentifier] = 0;
            updatedFormData[clearIdentifier] = 0;
            formData[clearIdentifier] = 0;
          });
         
        }
      }





      calculateFieldSum("section1_2024", "calculated_totalsale_2024");
      updatedFormData["calculated_wholesalepercentage"] =
        FindSection1WholesalePercentage(updatedFormData);

      updatedDataToPrepopulate["calculated_wholesalepercentage"] =
        FindSection1WholesalePercentage(updatedFormData);
    } else if (identifier.includes("section1_wholesalepercentage")) {
      updatedFormData["calculated_wholesalepercentage"] =
        FindSection1WholesalePercentage(updatedFormData);

      updatedDataToPrepopulate["calculated_wholesalepercentage"] =
        FindSection1WholesalePercentage(updatedFormData);
    } else if (identifier.includes("company_top_")) {
      let othercompanysales =
        parseInt(
          updatedFormData["company_top_nametop1total"]
            ? updatedFormData["company_top_nametop1total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop2total"]
            ? updatedFormData["company_top_nametop2total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop3total"]
            ? updatedFormData["company_top_nametop3total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop4total"]
            ? updatedFormData["company_top_nametop4total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop5total"]
            ? updatedFormData["company_top_nametop5total"]
            : 0
        ) +
        parseInt(
          updatedFormData["bayertotal2024"]
            ? updatedFormData["bayertotal2024"]
            : 0
        );

      updatedFormData["othercompaniesaggregatedtotal"] =
        updatedFormData["CP_totalsale_section1_2024"] - othercompanysales;

      updatedFormData["totalcompaniesaggregated"] = othercompanysales;

      updatedDataToPrepopulate["othercompaniesaggregatedtotal"] =
        updatedFormData["CP_totalsale_section1_2024"] - othercompanysales;

      updatedDataToPrepopulate["totalcompaniesaggregated"] = othercompanysales;
    } else if (identifier.includes("bayertotal2024")) {
      let othercompanysales =
        parseInt(
          updatedFormData["company_top_nametop1total"]
            ? updatedFormData["company_top_nametop1total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop2total"]
            ? updatedFormData["company_top_nametop2total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop3total"]
            ? updatedFormData["company_top_nametop3total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop4total"]
            ? updatedFormData["company_top_nametop4total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop5total"]
            ? updatedFormData["company_top_nametop5total"]
            : 0
        ) +
        parseInt(
          updatedFormData["bayertotal2024"]
            ? updatedFormData["bayertotal2024"]
            : 0
        );

      updatedFormData["othercompaniesaggregatedtotal"] =
        updatedFormData["CP_totalsale_section1_2024"] - othercompanysales;

      updatedFormData["totalcompaniesaggregated"] = othercompanysales;

      updatedDataToPrepopulate["othercompaniesaggregatedtotal"] =
        updatedFormData["CP_totalsale_section1_2024"] - othercompanysales;

      updatedDataToPrepopulate["totalcompaniesaggregated"] = othercompanysales;
    } else if (cornKeywords.includes(identifier)) {
      let data = await updateCornData(
        updatedFormData,
        updatedDataToPrepopulate,
        identifier
      );
      updatedFormData = await JSON.parse(JSON.stringify(data.updatedFormData1));
      updatedDataToPrepopulate = await JSON.parse(
        JSON.stringify(data.updatedDataToPrepopulate1)
      );
    } else if (identifier.includes("_RICE1")) {
      updatedFormData = calculateTotalRetailRiceVolume(updatedFormData);

      updatedDataToPrepopulate["totalRetailRiceVolume"] =
        safeParseInt(updatedFormData["retailvolumerice_RICE3"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE2"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE1"]) +
        safeParseInt(updatedFormData["bayerricewretailvolume_BayerRICE"]);

      let sum =
        updatedFormData["retailsalesrice_RICE1_totalsale"] /
        updatedFormData["retailvolumerice_RICE1"];

      sum = sum.toFixed(2);

      updatedFormData["averagendp1"] = sum;
      updatedDataToPrepopulate["averagendp1"] = sum;

      let TOTALPADDYSALE = parseInt(updatedFormData["SEE_paddyybrid_retail"])
        ? parseInt(updatedFormData["SEE_paddyybrid_retail"])
        : 0;
      let RICE1 = parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        : 0;
      let RICE2 = parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        : 0;
      let RICE3 = parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        : 0;
      let BAYERRICE = parseInt(
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]
      )
        ? parseInt(updatedFormData["bayerricetotal2024_BayerRICE_totalsale"])
        : 0;

      let OtherSum = TOTALPADDYSALE - (RICE1 + RICE2 + RICE3 + BAYERRICE);

      updatedFormData["otherRetailRice"] = OtherSum;
      updatedDataToPrepopulate["otherRetailRice"] = OtherSum;
      updatedFormData["totalRetailRice"] = TOTALPADDYSALE;
      updatedDataToPrepopulate["totalRetailRice"] = TOTALPADDYSALE;
    } else if (identifier.includes("_RICE2")) {
      updatedFormData = calculateTotalRetailRiceVolume(updatedFormData);
      updatedDataToPrepopulate["totalRetailRiceVolume"] =
        safeParseInt(updatedFormData["retailvolumerice_RICE3"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE2"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE1"]) +
        safeParseInt(updatedFormData["bayerricewretailvolume_BayerRICE"]);

      let sum =
        updatedFormData["retailsalesrice_RICE2_totalsale"] /
        updatedFormData["retailvolumerice_RICE2"];
      sum = sum.toFixed(2);

      updatedFormData["averagendp2"] = sum;
      updatedDataToPrepopulate["averagendp2"] = sum;

      let TOTALPADDYSALE = parseInt(updatedFormData["SEE_paddyybrid_retail"])
        ? parseInt(updatedFormData["SEE_paddyybrid_retail"])
        : 0;
      let RICE1 = parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        : 0;
      let RICE2 = parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        : 0;
      let RICE3 = parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        : 0;
      let BAYERRICE = parseInt(
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]
      )
        ? parseInt(updatedFormData["bayerricetotal2024_BayerRICE_totalsale"])
        : 0;

      let OtherSum = TOTALPADDYSALE - (RICE1 + RICE2 + RICE3 + BAYERRICE);

      updatedFormData["otherRetailRice"] = OtherSum;
      updatedDataToPrepopulate["otherRetailRice"] = OtherSum;
      updatedFormData["totalRetailRice"] = TOTALPADDYSALE;
      updatedDataToPrepopulate["totalRetailRice"] = TOTALPADDYSALE;
    } else if (identifier.includes("_RICE3")) {
      updatedFormData = calculateTotalRetailRiceVolume(updatedFormData);
      updatedDataToPrepopulate["totalRetailRiceVolume"] =
        safeParseInt(updatedFormData["retailvolumerice_RICE3"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE2"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE1"]) +
        safeParseInt(updatedFormData["bayerricewretailvolume_BayerRICE"]);

      let sum =
        updatedFormData["retailsalesrice_RICE3_totalsale"] /
        updatedFormData["retailvolumerice_RICE3"];
      sum = sum.toFixed(2);

      updatedFormData["averagendp3"] = sum;
      updatedDataToPrepopulate["averagendp3"] = sum;

      let TOTALPADDYSALE = parseInt(updatedFormData["SEE_paddyybrid_retail"])
        ? parseInt(updatedFormData["SEE_paddyybrid_retail"])
        : 0;
      let RICE1 = parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        : 0;
      let RICE2 = parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        : 0;
      let RICE3 = parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        : 0;
      let BAYERRICE = parseInt(
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]
      )
        ? parseInt(updatedFormData["bayerricetotal2024_BayerRICE_totalsale"])
        : 0;

      let OtherSum = TOTALPADDYSALE - (RICE1 + RICE2 + RICE3 + BAYERRICE);

      updatedFormData["otherRetailRice"] = OtherSum;
      updatedDataToPrepopulate["otherRetailRice"] = OtherSum;
      updatedFormData["totalRetailRice"] = TOTALPADDYSALE;
      updatedDataToPrepopulate["totalRetailRice"] = TOTALPADDYSALE;
    } else if (identifier.includes("_BayerRICE")) {
      updatedFormData = calculateTotalRetailRiceVolume(updatedFormData);
      updatedDataToPrepopulate["totalRetailRiceVolume"] =
        safeParseInt(updatedFormData["retailvolumerice_RICE3"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE2"]) +
        safeParseInt(updatedFormData["retailvolumerice_RICE1"]) +
        safeParseInt(updatedFormData["bayerricewretailvolume_BayerRICE"]);

      let sum =
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"] /
        updatedFormData["bayerricewretailvolume_BayerRICE"];
      sum = sum.toFixed(2);
      updatedFormData["averagendp_BayerRICE"] = sum;
      updatedDataToPrepopulate["averagendp_BayerRICE"] = sum;

      let TOTALPADDYSALE = parseInt(updatedFormData["SEE_paddyybrid_retail"])
        ? parseInt(updatedFormData["SEE_paddyybrid_retail"])
        : 0;
      let RICE1 = parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE1_totalsale"])
        : 0;
      let RICE2 = parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE2_totalsale"])
        : 0;
      let RICE3 = parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        ? parseInt(updatedFormData["retailsalesrice_RICE3_totalsale"])
        : 0;
      let BAYERRICE = parseInt(
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]
      )
        ? parseInt(updatedFormData["bayerricetotal2024_BayerRICE_totalsale"])
        : 0;

      let OtherSum = TOTALPADDYSALE - (RICE1 + RICE2 + RICE3 + BAYERRICE);

      updatedFormData["otherRetailRice"] = OtherSum;
      updatedDataToPrepopulate["otherRetailRice"] = OtherSum;

      updatedFormData["totalRetailRice"] = TOTALPADDYSALE;
      updatedDataToPrepopulate["totalRetailRice"] = TOTALPADDYSALE;
    } else if (identifier.includes("retailvolumerice_RICE3")) {
      let sum =
        updatedFormData["retailsalesrice_RICE3_totalsale"] /
        updatedFormData["retailvolumerice_RICE3"];
      sum = sum.toFixed(2);

      updatedFormData["averagendp3"] = sum;
      updatedDataToPrepopulate["averagendp3"] = sum;
    } else if (identifier.includes("retailvolumerice_RICE2")) {
      let sum =
        updatedFormData["retailsalesrice_RICE2_totalsale"] /
        updatedFormData["retailvolumerice_RICE2"];
      sum = sum.toFixed(2);

      updatedFormData["averagendp2"] = sum;
      updatedDataToPrepopulate["averagendp2"] = sum;
    } else if (identifier.includes("retailvolumerice_RICE1")) {
      let sum =
        updatedFormData["retailsalesrice_RICE1_totalsale"] /
        updatedFormData["retailvolumerice_RICE1"];
      sum = sum.toFixed(2);

      updatedFormData["averagendp1"] = sum;
      updatedDataToPrepopulate["averagendp1"] = sum;
    } else if (identifier.includes("bayerricewretailvolume_BayerRICE")) {
      let sum =
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"] /
        updatedFormData["bayerricewretailvolume_BayerRICE"];
      sum = sum.toFixed(2);
      updatedFormData["averagendp_BayerRICE"] = sum;
      updatedDataToPrepopulate["averagendp_BayerRICE"] = sum;
    } else if (identifier.includes("_totalsale")) {
      const retailKey = identifier.replace("_totalsale", "_retail");
      let total2024Key = "CP_totalsale_section1_2024";

      if (identifier.includes("SEE_")) {
        total2024Key = "AllSeed_totalsale_section1_2024";
      }

      updatedFormData[retailKey] = (
        (updatedFormData[total2024Key] ?? 0) *
        (value / 100)
      ).toFixed(2);

      updatedDataToPrepopulate[retailKey] = (
        (updatedFormData[total2024Key] ?? 0) *
        (value / 100)
      ).toFixed(2);

      if (identifier=== "SEE_cornhybrid_totalsale") {
       
        if(value === 0){
         
          clearDataArraySEEDSCorn.map((clearIdentifier)=>{
            updatedDataToPrepopulate[clearIdentifier] = 0;
            updatedFormData[clearIdentifier] = 0;
            formData[clearIdentifier] = 0;
          });
          
        }
      }


      if (identifier === "SEE_paddyybrid_totalsale") {
       
        if(value === 0){
         
          clearDataArraySEEDSRice.map((clearIdentifier)=>{
            updatedDataToPrepopulate[clearIdentifier] = 0;
            updatedFormData[clearIdentifier] = 0;
            formData[clearIdentifier] = 0;
          });
          
        }
      }


    } else if (identifier.includes("_wholesalepercentage")) {
      updatedFormData["totalwholesalesseedin2024"] =
        calculateWholeSale(updatedFormData);
      updatedDataToPrepopulate["totalwholesalesseedin2024"] =
        calculateWholeSale(updatedFormData);
    } else if (identifier.includes("crop_")) {
      updatedFormData["otherCrops"] =
        100 -
        parseInt(updatedFormData["crop_3_total"]) -
        parseInt(updatedFormData["crop_2_total"]) -
        parseInt(updatedFormData["crop_1_total"]);

      updatedDataToPrepopulate["otherCrops"] =
        100 -
        parseInt(updatedFormData["crop_3_total"]) -
        parseInt(updatedFormData["crop_2_total"]) -
        parseInt(updatedFormData["crop_1_total"]);
    }

    setDataToPrepopulate(updatedDataToPrepopulate);
    setFormData(updatedFormData);
  };

  const validateFields = () => {
    const errors = {};
    const temporaryConfig = JSON.parse(JSON.stringify(config));
    temporaryConfig.forEach((field) => {
      if (field.sectionquestions) {
        field.sectionquestions.map((questions) => {
          questions.questions.map((exactelement) => {
            const { identifier, isOptional } = exactelement;
            
            if((TempNonMandatory.includes(identifier) && (formData["CP_totalsale_section1_2024"]==0 || !formData["CP_totalsale_section1_2024"]))||
            (TempNonMandatorySeeds.includes(identifier) && (formData["AllSeed_totalsale_section1_2024"]==0 || !formData["AllSeed_totalsale_section1_2024"]))||
            (TempNonMandatorySeeds.includes(identifier) && (formData["SEE_cornhybrid_totalsale"]==0 || !formData["SEE_cornhybrid_totalsale"]))||
            (TempNonMandatoryPaddy.includes(identifier) && (formData["SEE_paddyybrid_totalsale"]==0 || !formData["SEE_paddyybrid_totalsale"]))
          ){
           

            delete formData[identifier];

            }else{
              if (
                !isOptional &&
                (typeof formData[identifier] === "undefined" ||
                  formData[identifier] === null || formData[identifier] === 0)
              ) {
                errors[identifier] = true;
              }
            }

            
          });
        });

        console.log(errors, '----')
      } else {
        field.questions.map((exactelement) => {
          const { identifier, isOptional } = exactelement;
          if (
            !isOptional &&
            (typeof formData[identifier] === "undefined" ||
              formData[identifier] === null || formData[identifier] === 0)
          ) {
            errors[identifier] = true;
          }
        });
      }
    });

    if (Object.keys(errors).length !== 0) {
      setShowError(true);
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const checkEquations = () => {};

  const moveToStep3 = () => {
    let tempConfig = config;

    // Adjust question optionality based on user input
    tempConfig.forEach((item) => {
      if (item.sectionquestions) {
        item.sectionquestions.forEach((sectionItem) => {
          const identifiers = sectionItem.questions.map((q) => q.identifier);

          const hasNonZeroValues = identifiers.some(
            (id) => formData[id] && parseFloat(formData[id]) >= 0
          );

          sectionItem.questions.forEach((question) => {

            if (
              [
                "cornhybrid_wholesalepercentage",
                "paddyybrid_wholesalepercentage",
                "vegetablehybrid_wholesalepercentage",
                "otherhybrid_wholesalepercentage",
                "totalwholesalesseedin2024",
              ].includes(question.identifier)
            ) {
              // this condition needs to be double checked
              if (
                formData["totalwholesalesseedin2024"] &&
                formData["totalwholesalesseedin2024"] === 0
              ) {
                question.isOptional =
                  !formData["AllSeed_section1_wholesalepercentage"] ||
                  parseFloat(
                    formData["AllSeed_section1_wholesalepercentage"]
                  ) === 0;
              }
            }





          // if attempting top 3 companies making other fields as mandatory
          if([
            "ricecompanytype3_RICE3",
            "retailsalesrice_RICE3_totalsale",
            "retailvolumerice_RICE3"
          ].includes(question.identifier)){
            if(typeof formData["companynamericetop3"] !== "undefined" &&
              formData["companynamericetop3"] !== null){
                question.isOptional = false
              }
          }
          if(["ricecompanytype2_RICE2",
            "retailsalesrice_RICE2_totalsale",
            "retailvolumerice_RICE2",].includes(question.identifier)){
              if(typeof formData["companynamericetop2"] !== "undefined" &&
                formData["companynamericetop2"] !== null){
                  question.isOptional = false
                }
            }

            if([
              "corn_company_user_type2",
              "corn_company_total_sale2",
              "corn_company_total_volume2"
            ].includes(question.identifier)){
              if(typeof formData["corn_company_name2"] !== "undefined" &&
                formData["corn_company_name2"] !== null){
                  question.isOptional = false
                }
            }
            if(["corn_company_user_type3",
              "corn_company_total_sale3",
              "corn_company_total_volume3",].includes(question.identifier)){
                if(typeof formData["corn_company_name3"] !== "undefined" &&
                  formData["corn_company_name3"] !== null){
                    question.isOptional = false
                  }
              }


            if (
              [
                "company_top_nametop1",
                "company_top_nametop2",
                "company_top_nametop3",
                "crop_1",
                "crop_2",
                "crop_3",
              ].includes(question.identifier)
            ) {
              question.isOptional =
                !formData["CP_totalsale_section1_2024"] ||
                parseFloat(formData["CP_totalsale_section1_2024"]) === 0;
            }

            if (
              [
                "companynamericetop1_RICE1",
                "ricecompanytype1_RICE1",
                "retailsalesrice_RICE1_totalsale",
                "retailvolumerice_RICE1",
                "wholesaleinpercentageRice_RICE1",
              ].includes(question.identifier)
            ) {
              question.isOptional =
                !formData["SEE_paddyybrid_totalsale"] ||
                parseFloat(formData["SEE_paddyybrid_totalsale"]) === 0;
            }
            if (
              [
                "corn_company_name1",
                "corn_company_user_type1",
                "corn_company_total_sale1",
                "corn_company_total_volume1",
                "corn_company_wholesale1",
              ].includes(question.identifier)
            ) {
              question.isOptional =
                !formData["SEE_cornhybrid_totalsale"] ||
                parseFloat(formData["SEE_cornhybrid_totalsale"]) === 0;
            }

          });
        });
      }
    });

    let summary = [];

    const validateSum = (totalField, fields, label) => {
      const total = parseFloat(formData[totalField] || 0);
      const sum = fields.reduce(
        (acc, field) => acc + parseFloat(formData[field] || 0),
        0
      );
      if (total !== sum) {
        let totalVal = formatNumberToUnits(total);
        let sumVal = formatNumberToUnits(sum);
        summary.push(
          <>
            <span style={{ color: "red", fontWeight: "bold" }}>{label}:</span>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              ₹{totalVal}{" "}
            </span>
            and sum of entered values is
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              ₹{sumVal},{" "}
            </span>
            it doesn't match. Please correct it.
          </>
        );
      }
    };

    // Validations with user-friendly messages
    validateSum(
      "CP_totalsale_section1_2024",
      [
        "insectiside_retail",
        "fungiside_retail",
        "herbiside_retail",
        "seedtreatment_retail",
      ],
      "Q2: Total Crop Protection Sales"
    );

    validateSum(
      "AllSeed_totalsale_section1_2024",
      [
        "SEE_cornhybrid_retail",
        "SEE_paddyybrid_retail",
        "SEE_vegetablehybrid_retail",
        "SEE_otherhybrid_retail",
      ],
      "Q9: Total Seed Sales"
    );

    validateSum(
      "CP_totalsale_section1_2024",
      [
        "fromdistributor_retail",
        "directfromcompany_retail",
        "online_retail",
        "govtbodies_retail",
        "others_retail",
      ],
      "Q3: Total Crop Protection products by each source"
    );

    validateSum(
      "SEE_cornhybrid_retail",
      ["corn_totalsale"],
      "Q10: Total Corn Hybrid Seed Sales"
    );

    const totalCrops = [
      parseFloat(formData["crop_1_total"] || 0),
      parseFloat(formData["crop_2_total"] || 0),
      parseFloat(formData["crop_3_total"] || 0),
    ];

    const totalCropSum = totalCrops.reduce((acc, val) => acc + val, 0);

    if (
      totalCropSum > 100 &&
      formData["CP_totalsale_section1_2024"] &&
      formData["CP_totalsale_section1_2024"] !== 0
    ) {
      summary.push(
        <>
          <span style={{ color: "red", fontWeight: "bold" }}>Q5: </span>
          Total Crops Sales Distribution for Crop Protection is greater than
          <span style={{ color: "red", fontWeight: "bold" }}> 100%. </span>
          Reduce it by:
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            {totalCropSum - 100}%.{" "}
          </span>
        </>
      );
    }

    // Ensure each crop's value is greater than 0%
    totalCrops.forEach((cropValue, index) => {
      if (
        cropValue <= 0 &&
        formData["CP_totalsale_section1_2024"] &&
        formData["CP_totalsale_section1_2024"] !== 0
      ) {
        summary.push(
          <>
            <span style={{ color: "red", fontWeight: "bold" }}>Q5: </span>
            Crop
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              {index + 1}{" "}
            </span>
            Sales distribution must be greater than{" "}
            <span style={{ color: "red", fontWeight: "bold" }}> 0% </span>
          </>
        );
      }
    });

    // make sure insecticide, fungiside, herbisde is not zero or skipped when CP sales is not zero

    if(formData["CP_totalsale_section1_2024"] && formData["CP_totalsale_section1_2024"]!==0){
      ["insectiside_totalsale",
      "fungiside_totalsale",
      "herbiside_totalsale"].map((mandate, index)=>{
        if(!formData[mandate] || formData[mandate]===0){
          summary.push(
            <>
              <span style={{ color: "red", fontWeight: "bold" }}>Q2: </span>
              {mandate == "insectiside_totalsale"
                ? "Insecticide cannot be empty OR zero"
                : mandate === "fungiside_totalsale"
                ? "Fungicide cannot be empty OR zero"
                : "Herbiside cannot be empty OR zero"}
              
            </>
          );
        }
      })
    }

    // -ve value scenario

    if(formData['othercompaniesaggregatedtotal'] && formData['othercompaniesaggregatedtotal']<0){
      summary.push(
        <>
          <span style={{ color: "red", fontWeight: "bold" }}>Q4: </span>
          The total should not be more than the total CP sales
          
        </>
      );
    }
    // 7th question
    if(formData['CP_totalsale_section1_2024'] && formData['CP_totalsale_section1_2024']>0){
      if(!formData["retailebusinessincash"] || formData["retailebusinessincash"]==null || formData["retailebusinessincash"]===0 ){
        summary.push( <>
          <span style={{ color: "red", fontWeight: "bold" }}>Q7: </span>
          The retail sales in cash is a mandatory field
        </>)
      }
    }

    // mandatory wholesale field for CP and SEED
    if(formData['CP_totalsale_section1_2024'] && formData['CP_totalsale_section1_2024'] >= 50000000){
      if(!formData["CP_section1_wholesalepercentage"] || formData["CP_section1_wholesalepercentage"]==0){
        summary.push( <>
          <span style={{ color: "red", fontWeight: "bold" }}>Q1: </span>
          The wholesale CP field is a mandatory field, please enter a value greater than 0%
        </>)
      }
    }
    if(formData['AllSeed_totalsale_section1_2024'] && formData['AllSeed_totalsale_section1_2024'] >= 50000000){
      if(!formData["AllSeed_section1_wholesalepercentage"] || formData["AllSeed_section1_wholesalepercentage"]==0){
        summary.push( <>
          <span style={{ color: "red", fontWeight: "bold" }}>Q1: </span>
          The wholesale SEED field is a mandatory field, please enter a value greater than 0%
        </>)
      }
    }



    // Show error summary if any validations fail
    if (summary.length > 0) {
      setErrorSummary(summary);
      setshowSummaryPOP(true);
      return;
    }

    if((!formData["CP_totalsale_section1_2024"] || formData["CP_totalsale_section1_2024"]===0 ) &&
    (!formData["AllSeed_totalsale_section1_2024"] || formData["AllSeed_totalsale_section1_2024"]===0 )){

     setShowError(true);
     return;
    }
    // Proceed to the next step if fields are valid
    if (validateFields()) {
      setReviewInfo(true);
    }
  };

  const close = () => {
    setReviewInfo(false);
  };

  const createPostBody = (selectedAnswers) => {
    let updatedPostBody = [];

    config.forEach((field) => {
      if (field.sectionquestions) {
        field.sectionquestions.map((questions) => {
          questions.questions.map((exactelement) => {
            updatedPostBody.push({
              answer: formData[exactelement.identifier],
              identifier: exactelement.identifier,
            });
          });
        });
      } else {
        field.questions.map((exactelement) => {
          updatedPostBody.push({
            answer: formData[exactelement.identifier],
            identifier: exactelement.identifier,
          });
        });
      }
    });

    return updatedPostBody;
  };

  const saveProgresses = async () => {
    let userID = await localStorage.getItem("user_id");
    dispatch(setLoader(true));
    setReviewInfo(false);
    let PostBody = createPostBody(formData);

    // Example usage:
    const formattedData = restructureAnswers(config, PostBody);
    formattedData.push({
      question_id: Step2QuestionIdConstants.IS_GST_INCLUDED,
      response: withGST,
    });
    const mobilenumber = await localStorage.getItem("mobile_number");
    const structured = {
      user_id: userID,
      page_number: "page2",
      responses: formattedData,
      action: "save",
      customer_id: data.state.customerID,
      mobile_number: mobilenumber,
    };

    post("/survey/save", structured)
      .then((response) => {
        window.alert("Data saved successfully!");
        dispatch(setLoader(false));
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        dispatch(setLoader(false));
        console.error("Error fetching data:", error);
      });
  };
  const proceed = async () => {
    let territoryID = await localStorage.getItem("territory_id");
    let userID = await localStorage.getItem("user_id");
    setReviewInfo(false);
    dispatch(setLoader(true));
    let PostBody = createPostBody(formData);

    const formattedData = restructureAnswers(config, PostBody);
    formattedData.push({
      question_id: Step2QuestionIdConstants.IS_GST_INCLUDED,
      response: withGST,
    });
    const mobilenumber = await localStorage.getItem("mobile_number");
    const structured = {
      user_id: data.state.user_id,
      page_number: "page2",
      responses: formattedData,
      action: "save",
      customer_id: data.state.customerID,
      mobile_number: mobilenumber,
    };

    post("/survey/save", structured)
      .then((response) => {
        dispatch(setLoader(true));
        navigate("/step3", {
          state: {
            customerID: data.state.customerID,
            user_id: userID,
            territory_id: territoryID,
          },
        });
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        dispatch(setLoader(true));
        console.error("Error fetching data:", error);
      });
  };
  const  isTenTimesGreater=(num1, num2)=> {
    return Math.abs(num1) >= 10 * Math.abs(num2) || Math.abs(num2) >= 10 * Math.abs(num1);
  }

  const renderField = (question, questionIndex) => {
    const {
      type,
      fieldName,
      identifier,
      placeholder,
      isHalf,
      isFirst,
      disabled,
      autopopulateColumn,
      isPercentage,
      isOptional,
    } = question;
    let { options } = question;

    if (
      [
        "companynamericetop1_RICE1",
        "companynamericetop2",
        "companynamericetop3",
        "company_top_nametop1",
        "company_top_nametop2",
        "company_top_nametop3",
        "company_top_nametop4",
        "company_top_nametop5",
        "crop_1",
        "crop_2",
        "crop_3",
      ].includes(identifier)
    ) {
      options = options.filter(
        (obj) =>
          obj.value !== dataToPrepopulate["companynamericetop1_RICE1"] &&
          obj.value !== dataToPrepopulate["companynamericetop2"] &&
          obj.value !== dataToPrepopulate["companynamericetop3"] &&
          obj.value !== dataToPrepopulate["company_top_nametop1"] &&
          obj.value !== dataToPrepopulate["company_top_nametop2"] &&
          obj.value !== dataToPrepopulate["company_top_nametop3"] &&
          obj.value !== dataToPrepopulate["company_top_nametop4"] &&
          obj.value !== dataToPrepopulate["company_top_nametop5"] &&
          obj.value !== dataToPrepopulate["crop_1"] &&
          obj.value !== dataToPrepopulate["crop_2"] &&
          obj.value !== dataToPrepopulate["crop_3"]
      );
    }

    const hasError = fieldErrors[identifier];
    switch (type) {
      case "text":
        return (
          <div
            key={identifier}
            className="form-group"
            style={
              isHalf
                ? {
                    width: "47%",
                    height: 93,
                    float: isFirst ? "left" : "right",
                    paddingRight: isFirst ? "3%" : "0%",
                    paddingLeft: isFirst ? "0%" : "3%",
                    opacity: disabled ? 0.3 : 1,
                    pointerEvents: disabled ? "none" : "all",
                  }
                : Style.fieldContainer
            }
          >
            {[
              "cornhybrid_wholesalepercentage",
              "paddyybrid_wholesalepercentage",
              "vegetablehybrid_wholesalepercentage",
              "otherhybrid_wholesalepercentage",
              "totalwholesalesseedin2024",
            ].includes(identifier) &&
            (!dataToPrepopulate["AllSeed_section1_wholesalepercentage"] ||
              dataToPrepopulate["AllSeed_section1_wholesalepercentage"] == 0 ||
              dataToPrepopulate["AllSeed_section1_wholesalepercentage"] ==
                "") ? (
              <span></span>
            ) : (
              <>
              {identifier === "CP_section1_wholesalepercentage" &&
               Math.abs(formData.CP_totalsale_section1_2024) > 2000000000 &&
              <span style={Style.infoText}>
                      It seems the entered value is more than 200CR, make sure the entered value is correct
                    </span>
              }
                {identifier === "CP_section1_wholesalepercentage" &&
                (formData.CP_totalsale_section1_2023!==0 && formData.CP_totalsale_section1_2024!==0)
                &&
                  isTenTimesGreater(
                    formData.CP_totalsale_section1_2023,
                    formData.CP_totalsale_section1_2024
                  ) && (
                    <span style={Style.infoText}>
                      It seems the 2023 and 2024 values are 10 times less/higher
                      than the other, please ensure the values are correct!
                    </span>
                  )}

                {identifier === "AllSeed_section1_wholesalepercentage" &&
              (formData.AllSeed_section1_2023!==0 &&
                formData.AllSeed_totalsale_section1_2024!==0)
                &&
                  isTenTimesGreater(
                    formData.AllSeed_section1_2023,
                    formData.AllSeed_totalsale_section1_2024
                  ) && (
                    <span style={Style.infoText}>
                      It seems the 2023 and 2024 values are 10 times less/higher
                      than the other, please ensure the values are correct!
                    </span>
                  )}

                <label style={Style.fieldName}>{fieldName}</label>

                {autopopulateColumn ? (
                  <div
                    style={{
                      ...Style.fieldElements,
                      pointerEvents: autopopulateColumn ? "none" : "all",
                      background: autopopulateColumn ? colors.Grey : "#fff",
                      height: 47,
                    }}
                  >
                    {dataToPrepopulate[identifier] &&
                      formatNumberToUnits(dataToPrepopulate[identifier])}
                  </div>
                ) : (
                  <input
                    type="number"
                    placeholder={placeholder}
                    value={
                      dataToPrepopulate[identifier] &&
                      dataToPrepopulate[identifier]
                    }
                    onChange={(e) => {
                      let inputvalue = e.target.value.replace(/\D/g, "");
                      if (isPercentage) {
                        if (inputvalue <= 100) {
                          if (inputvalue == "" || inputvalue == 0) {
                            handleChange(identifier, parseInt(0));
                          } else {
                            handleChange(
                              identifier,
                              parseInt(e.target.value.replace(/\D/g, ""))
                            );
                          }
                        }
                      } else {
                        if (inputvalue == "" || inputvalue == 0) {
                          handleChange(identifier, parseInt(0));
                        } else {
                          if (identifier === "noofretailersserve") {
                            let value = e.target.value;
                            value = value.replace(/[^0-9]/g, "");
                            if (value <= 1000) {
                              handleChange(
                                identifier,
                                parseInt(value.replace(/\D/g, ""))
                              );
                            }
                          } else {
                            if (
                              identifier === "wholesalebusinessincash" ||
                              identifier === "retailebusinessincash"
                            ) {
                              let value = e.target.value;
                              value = value.replace(/[^0-9]/g, "");
                              if (value <= 1000) {
                                handleChange(
                                  identifier,
                                  parseInt(value.replace(/\D/g, ""))
                                );
                              }
                            } else {
                              if (
                                identifier === "wholesalebusinessincash" ||
                                identifier === "retailebusinessincash"
                              ) {
                                let value = e.target.value;
                                value = value.replace(/[^0-9]/g, "");
                                if (value <= 100) {
                                  handleChange(
                                    identifier,
                                    parseInt(value.replace(/\D/g, ""))
                                  );
                                }
                              } else {
                                handleChange(
                                  identifier,
                                  parseInt(e.target.value.replace(/\D/g, ""))
                                );
                              }
                            }
                          }
                        }
                      }
                    }}
                    style={
                      autopopulateColumn
                        ? {
                            ...Style.fieldElements,
                            pointerEvents: autopopulateColumn ? "none" : "all",
                            background: autopopulateColumn
                              ? colors.Grey
                              : "#fff",
                          }
                        : hasError
                        ? { ...Style.fieldElements, border: "1px solid red" }
                        : isPercentage
                        ? Style.fieldElementsPer
                        : Style.fieldElements
                    }
                  />
                )}
                {hasError && (
                  <span
                    style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}
                  >
                    This is a required field!
                  </span>
                )}
              </>
            )}
          </div>
        );
      case "textWithUnit":
        return (
          <div
            key={identifier}
            className="form-group"
            style={
              isHalf
                ? {
                    width: "47%",
                    height: 93,
                    float: isFirst ? "left" : "right",
                    paddingRight: isFirst ? "3%" : "0%",
                    paddingLeft: isFirst ? "0%" : "3%",
                    opacity: disabled ? 0.3 : 1,
                    pointerEvents: disabled ? "none" : "all",
                  }
                : Style.fieldContainer
            }
          >
            <label style={Style.fieldName}>{fieldName}{!isOptional && (
          <mark style={{ color: "red", background: "#fff" }}>*</mark>
        )}</label>
            <TextInputWithUnit
              placeholder={placeholder}
              currentvalue={dataToPrepopulate[identifier]}
              onChange={(e, unit) => {
                // if(e > 2000000000){
                //   window.alert("Please recheck your inputs, You are entering a value greater than 200CR!")
                // }
                handleChange(identifier, e, unit);
              }}
              options={options}
              hasError={hasError}
            />
            {hasError && (
              <span
                style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}
              >
                This is a required field!
              </span>
            )}
          </div>
        );
      case "singleSelect":
        return (
          <SingleSelect
            identifier={identifier}
            isHalf={isHalf}
            isFirst={isFirst}
            fieldName={fieldName}
            formData={formData}
            handleChange={handleChange}
            placeholder={placeholder}
            options={options}
            disabled={false}
            hasError={hasError}
            isOptional={isOptional}
          />
        );
      default:
        return null;
    }
  };

  const renderSection = (section, i) => {
    const titleParts = section.sectionHead.split(' '); // Split title into words
    return (
      <>
        <div
          key={section.questionID}
          style={{
            paddingBottom:
              section.questionID ===
                Step2QuestionIdConstants.TOP_3_CORN_HYBRID_SEED_2024_QID ||
              section.questionID ===
                Step2QuestionIdConstants.CATEGORY_WISE_CP_SALES_2024_QID ||
              section.questionID ===
                Step2QuestionIdConstants.CP_PRODUCTS_FROM_EACH_SOURCE_2024_QID ||
              section.questionID ===
                Step2QuestionIdConstants.TOP_3_RICE_HYBRID_SEED_2024_QID
                ? 117
                : 0,
            position: "relative",
          }}
          className="section"
        >
          <h4
            style={{
              position: "sticky",
              top: 0,
              background: colors.White,
              zIndex: 8,
            }}
          >
            {i + 1}. {titleParts.map((word, index) => (
              <span
                
                style={{ color: word === 'Crop' || word === 'Seed' || word === 'Protection' || word === 'Seeds' || word === 'Hybrid' || word === 'Corn' || word === 'Rice' ? colors.Blue_Heilight : 'black' }}
              >
                {word} {' '} 
              </span>
            ))}
          </h4>
          

          {section.questionID ===
            Step2QuestionIdConstants.PERCENTAGE_CP_WHOLESALE_IN_CASH_QID &&
            (!formData.CP_section1_wholesalepercentage ||
              formData.CP_section1_wholesalepercentage === 0) && (
              <div style={Style.wrapper}>
                <span style={{ ...Font.RobotoBoldItalic }}>
                  Please update the wholesale Crop Protection sales to enable this section
                </span>
                <span
                  style={{
                    ...Font.RobotoMedium,
                    marginTop: 10,
                    display: "block",
                  }}
                >
                  (Leave it if you are not doing Crop Protection business.)
                </span>
              </div>
            )}
          {(section.questionID ===
            Step2QuestionIdConstants.CATEGORY_WISE_CP_SALES_2024_QID ||
            section.questionID ===
              Step2QuestionIdConstants.CP_PRODUCTS_FROM_EACH_SOURCE_2024_QID ||
            section.questionID ===
              Step2QuestionIdConstants.TOP_5_COMPANIES_CP_2024_QID ||
              section.questionID ===
              Step2QuestionIdConstants.PERCENTAGE_CP_RETAIL_IN_CASH_QID ||
            section.questionID ===
              Step2QuestionIdConstants.TOP_3_CROPS_CP_2024_QID) &&
            (!formData.CP_totalsale_section1_2024 ||
              formData.CP_totalsale_section1_2024 === 0) && (
              <div style={Style.wrapper}>
                <span style={{ ...Font.RobotoBoldItalic }}>
                  Please update the Total Crop Protection sales for 2024 to enable this
                  section
                </span>
                <span
                  style={{
                    ...Font.RobotoMedium,
                    marginTop: 10,
                    display: "block",
                  }}
                >
                  (Leave it if you are not doing Crop Protection business.)
                </span>
              </div>
            )}
          {section.questionID ===
            Step2QuestionIdConstants.CATEGORY_WISE_SEED_SALES_2024_QID &&
            (!formData.AllSeed_totalsale_section1_2024 ||
              formData.AllSeed_totalsale_section1_2024 === 0) && (
              <div style={Style.wrapper}>
                <span style={{ ...Font.RobotoBoldItalic }}>
                  Please update the Total Seed sales for 2024 to enable this
                  section.
                  <span
                    style={{
                      ...Font.RobotoMedium,
                      marginTop: 10,
                      display: "block",
                    }}
                  >
                    (Leave it if you are not doing Seeds business.)
                  </span>
                </span>
              </div>
            )}

          {section.questionID ===
            Step2QuestionIdConstants.TOP_3_CORN_HYBRID_SEED_2024_QID &&
            (!formData.SEE_cornhybrid_retail ||
              formData.SEE_cornhybrid_retail === 0 ||
              !formData.SEE_cornhybrid_totalsale ||
              formData.SEE_cornhybrid_totalsale == 0) && (
              <div style={Style.wrapper}>
                <span style={{ ...Font.RobotoBoldItalic }}>
                  Please update the Total Seed sales to enable this section.
                  <span
                    style={{
                      ...Font.RobotoMedium,
                      marginTop: 10,
                      display: "block",
                    }}
                  >
                    (Leave it if you are not doing Seeds business.)
                  </span>
                </span>
              </div>
            )}

          {section.questionID ===
            Step2QuestionIdConstants.TOP_3_RICE_HYBRID_SEED_2024_QID &&
            (!formData.SEE_paddyybrid_retail ||
              parseInt(formData.SEE_paddyybrid_retail) === 0) && (
              <div style={Style.wrapper}>
                <span style={{ ...Font.RobotoBoldItalic }}>
                  Please update the Total Seed sales for 2024 to enable this
                  section.
                </span>
                <span
                  style={{
                    ...Font.RobotoMedium,
                    marginTop: 10,
                    display: "block",
                  }}
                >
                  (Leave it if you are not doing Seeds business.)
                </span>
              </div>
            )}

          {dataToPrepopulate && (
            <>
              {section.questionID ===
                Step2QuestionIdConstants.CATEGORY_WISE_CP_SALES_2024_QID && (
                <HeighlightBar
                  typeOfsale={"Crop Protection"}
                  value={formData}
                />
              )}
              {section.questionID ===
                Step2QuestionIdConstants.CATEGORY_WISE_SEED_SALES_2024_QID && (
                <HeighlightBar typeOfsale={"Seeds"} value={formData} />
              )}
              {section.questionID ===
                Step2QuestionIdConstants.TOP_3_RICE_HYBRID_SEED_2024_QID && (
                <HeighlightBar
                  typeOfsale={"Rice(Hybrid Seed)"}
                  value={formData}
                />
              )}
              {section.questionID ===
                Step2QuestionIdConstants.CP_PRODUCTS_FROM_EACH_SOURCE_2024_QID && (
                <div
                  style={{
                    background:
                      parseInt(
                        formData["fromdistributor_retail"]
                          ? formData["fromdistributor_retail"]
                          : 0
                      ) +
                        parseInt(
                          formData["directfromcompany_retail"]
                            ? formData["directfromcompany_retail"]
                            : 0
                        ) +
                        parseInt(
                          formData["govtbodies_retail"]
                            ? formData["govtbodies_retail"]
                            : 0
                        ) +
                        parseInt(
                          formData["online_retail"]
                            ? formData["online_retail"]
                            : 0
                        ) +
                        parseInt(
                          formData["others_retail"]
                            ? formData["others_retail"]
                            : 0
                        ) >
                      formData["CP_totalsale_section1_2024"]
                        ? "#ffa4a4"
                        : "#e1f2e1",
                    padding: 20,
                    marginBottom: 20,
                    position: "sticky",
                    top: 0,
                    zIndex: 9,
                  }}
                >
                  <span style={Style.Totalsale}>
                    Total Crop Protection Sales in 2024
                  </span>
                  <span>
                    ₹
                    {formatNumberToUnits(
                      formData["CP_totalsale_section1_2024"]
                    )}{" "}
                    (
                    {(
                      ((parseInt(
                        formData["fromdistributor_retail"]
                          ? formData["fromdistributor_retail"]
                          : 0
                      ) +
                        parseInt(
                          formData["directfromcompany_retail"]
                            ? formData["directfromcompany_retail"]
                            : 0
                        ) +
                        parseInt(
                          formData["govtbodies_retail"]
                            ? formData["govtbodies_retail"]
                            : 0
                        ) +
                        parseInt(
                          formData["online_retail"]
                            ? formData["online_retail"]
                            : 0
                        ) +
                        parseInt(
                          formData["others_retail"]
                            ? formData["others_retail"]
                            : 0
                        )) /
                        formData["CP_totalsale_section1_2024"]) *
                      100
                    ).toFixed(2)}{" "}
                    %)
                  </span>
                  {parseInt(
                    formData["fromdistributor_retail"]
                      ? formData["fromdistributor_retail"]
                      : 0
                  ) +
                    parseInt(
                      formData["directfromcompany_retail"]
                        ? formData["directfromcompany_retail"]
                        : 0
                    ) +
                    parseInt(
                      formData["govtbodies_retail"]
                        ? formData["govtbodies_retail"]
                        : 0
                    ) +
                    parseInt(
                      formData["online_retail"] ? formData["online_retail"] : 0
                    ) +
                    parseInt(
                      formData["others_retail"] ? formData["others_retail"] : 0
                    ) >
                    formData["CP_totalsale_section1_2024"] && (
                    <span
                      style={{
                        display: "block",
                        fontSize: 12,
                        marginTop: 10,
                        ...Font.RobotoMedium,
                      }}
                    >
                      The Total should not be more than the total Crop Protection sales
                    </span>
                  )}
                </div>
              )}
              {section.questionID ===
                Step2QuestionIdConstants.TOP_5_COMPANIES_CP_2024_QID && (
                <div
                  style={{
                    background:
                      parseInt(formData["totalcompaniesaggregated"]) >
                      formData["CP_totalsale_section1_2024"]
                        ? "#ffa4a4"
                        : "#e1f2e1",
                    padding: 20,
                    marginBottom: 20,
                    position: "sticky",
                    top: 0,
                    zIndex: 9,
                  }}
                >
                  <span style={Style.Totalsale}>
                    Total Crop Protection Sales in 2024
                  </span>
                  <span>
                    ₹
                    {formatNumberToUnits(
                      formData["CP_totalsale_section1_2024"]
                    )}{" "}
                    ₹
                    {formatNumberToUnits(
                      parseInt(formData["totalcompaniesaggregated"])
                    ) &&
                    formatNumberToUnits(
                      parseInt(formData["totalcompaniesaggregated"])
                    ) !== 0
                      ? "(" +
                        formatNumberToUnits(
                          parseInt(formData["totalcompaniesaggregated"])
                        ) +
                        ")"
                      : ""}
                  </span>
                  {parseInt(formData["totalcompaniesaggregated"]) >
                    formData["CP_totalsale_section1_2024"] && (
                    <span
                      style={{
                        display: "block",
                        fontSize: 12,
                        marginTop: 10,
                        ...Font.RobotoMedium,
                      }}
                    >
                      The Total should not be more than the total Crop Protection sales
                    </span>
                  )}
                </div>
              )}
              {section.questionID ===
                Step2QuestionIdConstants.TOP_3_CORN_HYBRID_SEED_2024_QID && (
                <div
                  style={{
                    background:
                      parseInt(formData["corn_other_companies"]) >
                      formData["SEE_cornhybrid_retail"]
                        ? "#ffa4a4"
                        : "#e1f2e1",
                    padding: 20,
                    marginBottom: 20,
                    position: "sticky",
                    top: 0,
                    zIndex: 9,
                  }}
                >
                  <span style={Style.Totalsale}>
                    Total Corn Seeds Sales in 2024
                  </span>
                  <span>
                    ₹{formatNumberToUnits(formData["SEE_cornhybrid_retail"])}{" "}
                    {parseInt(formData["corn_other_companies"]) &&
                    parseInt(formData["corn_other_companies"]) !== 0
                      ? "(" +
                        formatNumberToUnits(
                          parseInt(formData["corn_other_companies"])
                        ) +
                        ")"
                      : ""}
                  </span>
                  {parseInt(formData["corn_other_companies"]) >
                    formData["SEE_cornhybrid_retail"] && (
                    <span
                      style={{
                        display: "block",
                        fontSize: 12,
                        marginTop: 10,
                        ...Font.RobotoMedium,
                      }}
                    >
                      The Total should not be more than the total Corn sales
                    </span>
                  )}
                  {parseInt(formData["corn_other_companies"]) < 0 && (
                    <span
                      style={{
                        display: "block",
                        fontSize: 12,
                        marginTop: 10,
                        background: "red",
                        padding: "10px 0px",
                        ...Font.RobotoMedium,
                      }}
                    >
                      The Total should not be more than the total Corn sales
                    </span>
                  )}
                </div>
              )}
              {section.questionID ===
                Step2QuestionIdConstants.TOP_3_CROPS_CP_2024_QID && (
                <div
                  style={{
                    background:
                      parseInt(formData["otherCrops"]) < 0
                        ? "#ffa4a4"
                        : "#e1f2e1",
                    padding: 20,
                    marginBottom: 20,
                    position: "sticky",
                    top: 0,
                    zIndex: 9,
                  }}
                >
                  <span style={Style.Totalsale}>
                    Total Crop Protection Sales in 2024
                  </span>
                  <span>
                    ₹
                    {formatNumberToUnits(
                      formData["CP_totalsale_section1_2024"]
                    )}
                  </span>
                </div>
              )}
            </>
          )}

          {section.questions &&
            section.questions.map((question, index) =>
              renderField(question, index)
            )}

          {section.sectionquestions &&
            section.sectionquestions.map((subsection, subIndex) => (
              <div key={subIndex} style={{ marginBottom: 20 }}>
                {!(
                  data.state.bayerSeller == "no" &&
                  subsection.subhead == "Bayer"
                ) && (
                  <>
                    <span style={Style.subhead}>{subsection.subhead}</span>
                    {subsection.questions.map((question, index) =>
                      renderField(question, index)
                    )}
                  </>
                )}
              </div>
            ))}

          {section.havingtaileend &&
            section.havingtaileend.questions.map((question, index) =>
              renderField(question, index)
            )}
        </div>
      </>
    );
  };
  const showSaveProgress = () => {
    setSaveProgress(true);
  };

  return (
    <div>
      {Utils.loading && <LoaderFaded />}
      {showError && (
        <InfoPopUp
          close={() => {
            setShowError(false);
          }}
          heading={"Update Mandatory* Fields"}
          text={`Please update all the mandatory fields to proceed further ${((!formData["CP_totalsale_section1_2024"] || formData["CP_totalsale_section1_2024"]===0 ) &&
            (!formData["AllSeed_totalsale_section1_2024"] || formData["AllSeed_totalsale_section1_2024"]===0 )) && "NOTE: CP and SEED sales cannot be Empty"}`}
        />
      )}
      {showSummaryPOP && errorSummary.length > 0 && (
        <SummaryPopUp
          close={() => {
            setErrorSummary([]);
            setshowSummaryPOP(false);
          }}
          errorSummary={errorSummary}
        />
      )}

      {reviewInfo && <ProceedNotification close={close} proceed={proceed} />}
      {saveProgress && (
        <SaveProgress
          close={() => {
            setSaveProgress(false);
          }}
          saveprogress={async () => {
            let territoryID = await localStorage.getItem("territory_id");
            let userID = await localStorage.getItem("user_id");
            saveProgresses();
            setSaveProgress(false);
            navigate(`/?territory_id=${territoryID}&fo_id=${userID}`);
          }}
        />
      )}
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.step2}
        showSaveProgress={showSaveProgress}
      />
      <div style={Style.checkboxContain} onClick={()=>{setWithGST(!withGST)}}>
          {withGST?  <img
              src={Checked}
              style={{ width: 30, height: 30 }}
              alt="loader"
                    />:  <img
              src={Unchecked}
              style={{ width: 30, height: 30, borderRadius:4 }}
              alt="loader"
                    />}
         
          <div>
            <span style={{display:'block', marginLeft:10, ...Font.RobotoBold}}>Sales value should be including GST</span>
            {withGST ? <div style={{marginLeft:10,marginTop:10}}>
              <span style={{fontSize:11, display:'block',marginBottom:5}}>Crop Protection - <mark style={{...Font.RobotoBold, background:'transparent'}}>18% GST</mark> Applicable</span>
              <span style={{fontSize:11, display:'block',marginBottom:5}}>Fertilizer - <mark style={{...Font.RobotoBold, background:'transparent'}}>5% GST</mark> Applicable</span>
              <span style={{fontSize:11, display:'block'}}>Seed - <mark style={{...Font.RobotoBold, background:'transparent'}}>0%  GST</mark> Applicable</span>
            </div>:<span>
              
              </span>}
          </div>
        </div>
      <div style={Style.container}>
        {withGST == false ? (
          <div>Please select the checkbox to continue!</div>
        ) : (
          config.map((section, i) => renderSection(section, i))
        )}
      </div>
      {withGST !== false && (
        <SaveAndCont onContinue={moveToStep3} saveProgress={saveProgresses} />
      )}
    </div>
  );
};
