import { Step1QuestionIdConstants } from "../constants/textconstants";
import { getStates } from "../helper-functions";

export const config1 = [
  {
    fieldName: "Contact Person Name",
    isOptional: false,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_1,
    placeholder: "Enter name",
  },
  {
    fieldName: "Mobile Number",
    isOptional: false,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_2,
    placeholder: "Mobile Number",
  },
  {
    fieldName: "Email Address",
    isOptional: true,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_3,
    placeholder: "Enter a valid email address",
  },
  {
    fieldName: "Age",
    isOptional: false,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_4,
    placeholder: "Number of years between 18 to 99",
  },
  {
    fieldName: "Shop Name",
    isOptional: false,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_5,
    placeholder: "Enter name as per Govt. records",
  },
  {
    fieldName: "Type of Establishment",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_6,
    placeholder: "Type of establishment",
    options: [
      { id: 1, value: "Proprietorship" },
      { id: 2, value: "Partnership" },
      { id: 3, value: "Company" },
    ],
  },
  {
    fieldName: "Do you sell Bayer Products?",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_7,
    placeholder: "Select",
    options: [
      { id: 1, value: "yes" },
      { id: 2, value: "no" },
    ],
  },
  {
    fieldName: "Customer Type",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_8,
    placeholder: "Customer Type",
    options: [
      { id: 1, value: "Distributor" },
      { id: 2, value: "Retailer" },
      { id: 3, value: "FPO" },
      { id: 4, value: "BLF" },
      { id: 5, value: "Cooperative Socities/Govt Bodies" },
      { id: 6, value: "e-commerce" },
    ],
  },

  {
    fieldName: "State",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_9,
    placeholder: "Select",
    options: await getStates(),
  },
  {
    fieldName: "District",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_10,
    placeholder: "Select",
    options: [],
    isHalf: true,
    isFirst: true,
  },
  {
    fieldName: "Sub District",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_11,
    placeholder: "Select",
    options: [],
    isHalf: true,
    isFirst: false,
  },
  {
    fieldName: "Choose between Village or Urban Body",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_12,
    placeholder: "Select",
    options: [
      { id: Step1QuestionIdConstants.QUESTION_12, value: "Village" },
      { id: Step1QuestionIdConstants.QUESTION_14, value: "Urban Body" },
    ],
  },
  {
    fieldName: "Village",
    isOptional: true,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_13,
    placeholder: "Select",
    options: [],
    isHalf: true,
    isFirst: true,
  },
  {
    fieldName: "Urban Body",
    isOptional: true,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_14,
    placeholder: "Select",
    options: [],
    isHalf: true,
    isFirst: false,
  },
  {
    fieldName: "Territory",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_15,
    placeholder: "Select",
    options: [],
    isHalf: true,
    isFirst: true,
  },
  {
    fieldName: "FOT",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_16,
    placeholder: "Select",
    options: [],
    isHalf: true,
    isFirst: false,
  },
  {
    fieldName: "PIN Code",
    isOptional: false,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_17,
    placeholder: "Enter 6-Digit PIN code",
  },
  {
    fieldName: "GSTIN Number",
    isOptional: true,
    type: "text",
    identifier: Step1QuestionIdConstants.QUESTION_18,
    placeholder: "Enter your 15 digit GSTIN number",
  },
  {
    fieldName: "No. of years in Pesticides/Seed Business",
    isOptional: false,
    type: "singleSelect",
    identifier: Step1QuestionIdConstants.QUESTION_19,
    placeholder: "Select",
    options: [
      { id: 1, value: "Less than 5 years" },
      { id: 2, value: "Between 5-10 years" },
      { id: 3, value: "Between 11-15 years" },
      { id: 4, value: "Between 16-20 years" },
      { id: 4, value: "Between 21-25 years" },
      { id: 5, value: "More than 25 years" },
    ],
  },
  {
    fieldName: "Are you a Farmer",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_20,
    placeholder: "Select",
    options: [
      { id: 1, value: "yes" },
      { id: 2, value: "no" },
    ],
  },
  {
    fieldName: "Are you an Agri-Graduate",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_21,
    placeholder: "Select",
    options: [
      { id: 1, value: "yes" },
      { id: 2, value: "no" },
    ],
  },
  {
    fieldName: "Do you have a License to sell Pesticides?",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_22,
    placeholder: "Select",
    options: [
      { id: 1, value: "yes" },
      { id: 2, value: "no" },
    ],
  },
  {
    fieldName: "Do you have License to sell Seeds?",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_23,
    placeholder: "Select",
    options: [
      { id: 1, value: "yes" },
      { id: 2, value: "no" },
    ],
  },
  {
    fieldName: "Do you have License to sell Fertilizers?",
    isOptional: false,
    type: "radio",
    identifier: Step1QuestionIdConstants.QUESTION_24,
    placeholder: "Select",
    options: [
      { id: 1, value: "yes" },
      { id: 2, value: "no" },
    ],
  },
];
