import {
  companyNamesSeeds,
  CompanyNamesCP,
  croplist,
} from "./statesanddistricts";
import { Step2QuestionIdConstants } from "../constants/textconstants";

export const config = [
  {
    sectionHead:
      "Please provide category wise Annual sales for the year (Jan-Dec) 2023 & 2024 including retail & wholesale",
    questionID: Step2QuestionIdConstants.CATEGORY_WISE_ANNUAL_SALES_QID,
    sectionquestions: [
      {
        subhead: "Crop Protection",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: true,
            type: "textWithUnit",
            identifier: "CP_totalsale_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "CP_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "CP_section1_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Biologic Pesticides(eg: Rhapsody, Neem based, BT)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: true,
            type: "textWithUnit",
            identifier: "Biologic_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "Biologic_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "Biologic_section1_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead:
          "PGR + Bio stimulants (eg: Ethrel, Planofix, Ambition, Biovita, Biozymes etc)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: true,
            type: "textWithUnit",
            identifier: "PGR_totalsale_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "PGR_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "PGR_section1_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "All Seeds(including Veg, Hybrid, OPV)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: true,
            type: "textWithUnit",
            identifier: "AllSeed_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "AllSeed_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "AllSeed_section1_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Soluble fertilizer (NPK)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: true,
            type: "textWithUnit",
            identifier: "solubleFertilizer_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "solubleFertilizer_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "solubleFertilizer_section1_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Fertilizers (eg: Urea, DAP, MOP etc)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: true,
            type: "textWithUnit",
            identifier: "bulkFertilizer_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "bulkFertilizer_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "bulkFertilizer_section1_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Total Sales",
        questions: [
          {
            fieldName: "2023 Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "calculated_totalsale_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            autopopulateColumn: true,
          },
          {
            fieldName: "2024 Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "calculated_totalsale_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
          {
            fieldName: "Wholesale in 2024 (in %)",
            isOptional: true,
            type: "text",
            identifier: "calculated_wholesalepercentage",
            placeholder: "Input here",
            autopopulateColumn: true,
          },
        ],
      },
    ],
  },

  {
    sectionHead:
      "Please provide your category wise Crop Protection Business sales for the year (Jan-Dec) 2024 including retail & wholesale",
    questionID: Step2QuestionIdConstants.CATEGORY_WISE_CP_SALES_2024_QID,
    sectionquestions: [
      {
        subhead: "Insecticide",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "insectiside_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "insectiside_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Fungicide",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "fungiside_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "fungiside_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Herbicide",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "herbiside_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "herbiside_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Seed Treatment",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "seedtreatment_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "seedtreatment_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
    ],
  },
  {
    sectionHead:
      "What percentage of your Crop Protection products did you purchase from each source in 2024?",
    questionID: Step2QuestionIdConstants.CP_PRODUCTS_FROM_EACH_SOURCE_2024_QID,
    sectionquestions: [
      {
        subhead: "Direct from company",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: true,
            type: "text",
            identifier: "directfromcompany_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "directfromcompany_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "From distributor",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: true,
            type: "text",
            identifier: "fromdistributor_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "fromdistributor_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Online stores(Dehaat, Agrolink, etc)",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: true,
            type: "text",
            identifier: "online_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "online_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Govt Bodies",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: true,
            type: "text",
            identifier: "govtbodies_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "govtbodies_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Other Wholesalers",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: true,
            type: "text",
            identifier: "others_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "others_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
    ],
  },

  {
    sectionHead: "Top 5 companies Crop Protection business in 2024",
    questionID: Step2QuestionIdConstants.TOP_5_COMPANIES_CP_2024_QID,
    sectionquestions: [
      {
        subhead: "Company 1",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop1",
            placeholder: "Select",
            options: CompanyNamesCP.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type1",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop1total",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "company_top_namtotalsale1",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Company 2",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop2",
            placeholder: "Select",
            options: CompanyNamesCP.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type2",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop2total",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "company_top_namtotalsale2",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Company 3",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop3",
            placeholder: "Select",
            options: CompanyNamesCP.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type3",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop3total",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "company_top_namtotalsale3",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Company 4",
        questions: [
          {
            fieldName: "company",
            isOptional: true,
            type: "singleSelect",
            identifier: "company_top_nametop4",
            placeholder: "Select",
            options: CompanyNamesCP.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "company_top_type4",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "total sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "company_top_nametop4total",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "company_top_namtotalsale4",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Company 5",
        questions: [
          {
            fieldName: "company",
            isOptional: true,
            type: "singleSelect",
            identifier: "company_top_nametop5",
            placeholder: "Select",
            options: CompanyNamesCP.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "company_top_type5",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "total sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "company_top_nametop5total",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "company_top_namtotalsale5",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Bayer",
        questions: [
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "bayeruserType",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: false,
            isFirst: false,
          },
          {
            fieldName: "total Sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "bayertotal2024",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "bayerwholesale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },

      {
        subhead: "Other Companies",
        questions: [
          {
            fieldName: "Total sales in Other Companies (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "othercompaniesaggregatedtotal",
            placeholder: "Input here",
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Total sales (Top 5 + Bayer)",
        questions: [
          {
            fieldName: "Total sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "totalcompaniesaggregated",
            placeholder: "Input here",
            autopopulateColumn: true,
          },
        ],
      },
    ],
  },
  {
    sectionHead:
      "Please provide Top-3 Crops contributed to your Crop Protection Business in 2024",
    questionID: Step2QuestionIdConstants.TOP_3_CROPS_CP_2024_QID,
    sectionquestions: [
      {
        subhead: "Crop 1",
        questions: [
          {
            fieldName: "Crop",
            isOptional: false,
            type: "singleSelect",
            options: croplist,
            placeholder: "Select",
            identifier: "crop_1",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales(In %)",
            isOptional: true,
            type: "text",
            identifier: "crop_1_total",
            placeholder: "Enter here in %",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Crop 2",
        questions: [
          {
            fieldName: "Crop",
            isOptional: false,
            type: "singleSelect",
            options: croplist,
            placeholder: "Select",
            identifier: "crop_2",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales(In %)",
            isOptional: true,
            type: "text",
            identifier: "crop_2_total",
            placeholder: "Enter here in %",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Crop 3",
        questions: [
          {
            fieldName: "Crop",
            isOptional: false,
            type: "singleSelect",
            options: croplist,
            placeholder: "Select",
            identifier: "crop_3",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales(In %)",
            isOptional: true,
            type: "text",
            identifier: "crop_3_total",
            placeholder: "Enter here in %",
            isHalf: true,
            isFirst: false,
            isPercentage: true,
          },
        ],
      },
    ],
    havingtaileend: {
      questions: [
        {
          fieldName: "Other Crops Total Sales in %",
          isOptional: true,
          type: "text",
          identifier: "otherCrops",
          placeholder: "Input here",
          autopopulateColumn: true,
        },
      ],
    },
  },
  {
    sectionHead:
      "What percentage of your Crop Protection wholesale business is in cash?",
    questionID: Step2QuestionIdConstants.PERCENTAGE_CP_WHOLESALE_IN_CASH_QID,
    questions: [
      {
        fieldName: "",
        isOptional: true,
        type: "text",
        identifier: "wholesalebusinessincash",
        placeholder: "Enter here in %",
        isPercentage: true,
      },
    ],
  },
  {
    sectionHead:
      "What percentage of your Crop Protection retail business is in cash?",
    questionID: Step2QuestionIdConstants.PERCENTAGE_CP_RETAIL_IN_CASH_QID,
    questions: [
      {
        fieldName: "",
        isOptional: true,
        type: "text",
        identifier: "retailebusinessincash",
        placeholder: "Enter here in %",
        isPercentage: true,
      },
    ],
  },

  {
    sectionHead: "What is the total no. of retailers you serve?",
    questionID: Step2QuestionIdConstants.TOTAL_NO_OF_RETAILERS_SERVE_QID,
    questions: [
      {
        fieldName: "",
        isOptional: true,
        type: "text",
        identifier: "noofretailersserve",
        placeholder: "Enter here in number",
      },
    ],
  },

  {
    sectionHead:
      "Please provide your category-wise Seed Business sales (wholesale and retail) in 2024",
    questionID: Step2QuestionIdConstants.CATEGORY_WISE_SEED_SALES_2024_QID,
    sectionquestions: [
      {
        subhead: "Corn Hybrids",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "SEE_cornhybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "SEE_cornhybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "cornhybrid_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Rice Hybrids",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "SEE_paddyybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "SEE_paddyybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "paddyybrid_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Vegetable Hybrids",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "SEE_vegetablehybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "SEE_vegetablehybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "vegetablehybrid_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
      {
        subhead: "Other Seeds(OPV & Other hybrids)",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: true,
            type: "text",
            identifier: "SEE_otherhybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Total Sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "SEE_otherhybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: true,
            type: "text",
            identifier: "otherhybrid_wholesalepercentage",
            placeholder: "Input here",
            isPercentage: true,
          },
        ],
      },
    ],
    havingtaileend: {
      questions: [
        {
          fieldName: "Total wholesale in 2024 (in%)",
          isOptional: true,
          type: "text",
          identifier: "totalwholesalesseedin2024",
          placeholder: "Input here",
          autopopulateColumn: true,
        },
      ],
    },
  },
  {
    sectionHead: "Top 3 companies Corn Hybrid Seed Business in 2024",
    questionID: Step2QuestionIdConstants.TOP_3_CORN_HYBRID_SEED_2024_QID,
    sectionquestions: [
      {
        subhead: "Company 1",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "corn_company_name1",
            placeholder: "Select",
            options: companyNamesSeeds.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "corn_company_user_type1",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "corn_company_total_sale1",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume",
            isOptional: false,
            type: "textWithUnit",
            identifier: "corn_company_total_volume1",
            placeholder: "Input here",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "corn_company_wholesale1",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "corn_company_averagendp1",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Company 2",
        questions: [
          {
            fieldName: "company",
            isOptional: true,
            type: "singleSelect",
            identifier: "corn_company_name2",
            placeholder: "Select",
            options: companyNamesSeeds.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "corn_company_user_type2",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Total sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "corn_company_total_sale2",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume",
            isOptional: true,
            type: "textWithUnit",
            identifier: "corn_company_total_volume2",
            placeholder: "Input here",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "corn_company_wholesale2",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "corn_company_averagendp2",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Company 3",
        questions: [
          {
            fieldName: "company",
            isOptional: true,
            type: "singleSelect",
            identifier: "corn_company_name3",
            placeholder: "Select",
            options: companyNamesSeeds.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "corn_company_user_type3",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Total sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "corn_company_total_sale3",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume",
            isOptional: true,
            type: "textWithUnit",
            identifier: "corn_company_total_volume3",
            placeholder: "Input here",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "corn_company_wholesale3",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "corn_company_averagendp3",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },

      {
        subhead: "Bayer",
        questions: [
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "corn_bayer_type",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: false,
            isFirst: false,
          },
          {
            fieldName: "Total sale in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "corn_bayer_total_sale_2024",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total Volume",
            isOptional: true,
            type: "textWithUnit",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            identifier: "corn_bayer_total_sale_mt",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "corn_bayer_wholesale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "corn_bayer_averagendp",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },

      {
        subhead: "Other Companies",
        questions: [
          {
            fieldName: "Total Sales in Other Companies (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "corn_other_companies",
            placeholder: "Input here",
            autopopulateColumn: true,
          },
        ],
      },

      {
        subhead: "Total sales and volume",
        questions: [
          {
            fieldName: "Total sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "corn_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            autopopulateColumn: true,
          },
          {
            fieldName: "Total Volume (in KGs)",
            isOptional: true,
            type: "text",
            identifier: "corn_totalvolume",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
    ],
  },
  {
    sectionHead: "Top 3 companies Rice Hybrid Seed businesses in 2024",
    questionID: Step2QuestionIdConstants.TOP_3_RICE_HYBRID_SEED_2024_QID,
    sectionquestions: [
      {
        subhead: "Company 1",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "companynamericetop1_RICE1",
            placeholder: "Select",
            options: companyNamesSeeds.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "ricecompanytype1_RICE1",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailsalesrice_RICE1_totalsale",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailvolumerice_RICE1",
            placeholder: "Input here",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "wholesaleinpercentageRice_RICE1",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "averagendp1",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Company 2",
        questions: [
          {
            fieldName: "company",
            isOptional: true,
            type: "singleSelect",
            identifier: "companynamericetop2",
            placeholder: "Select",
            options: companyNamesSeeds.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "ricecompanytype2_RICE2",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Total sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "retailsalesrice_RICE2_totalsale",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume",
            isOptional: true,
            type: "textWithUnit",
            identifier: "retailvolumerice_RICE2",
            placeholder: "Input here",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "wholesaleinpercentageRice_RICE2",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "averagendp2",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Company 3",
        questions: [
          {
            fieldName: "company",
            isOptional: true,
            type: "singleSelect",
            identifier: "companynamericetop3",
            placeholder: "Select",
            options: companyNamesSeeds.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "ricecompanytype3_RICE3",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Total sales 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "retailsalesrice_RICE3_totalsale",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume",
            isOptional: true,
            type: "textWithUnit",
            identifier: "retailvolumerice_RICE3",
            placeholder: "Input here",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "wholesaleinpercentageRice_RICE3",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "averagendp3",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Bayer",
        questions: [
          {
            fieldName: "Retailer/Distributor",
            isOptional: true,
            type: "singleSelect",
            identifier: "bayeruserTyperice_BayerRICE",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Retailer" },
              { id: 2, value: "Distributor" },
            ],
            isHalf: false,
            isFirst: false,
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: true,
            type: "textWithUnit",
            identifier: "bayerricetotal2024_BayerRICE_totalsale",
            placeholder: "Input here",
            options: [
              { id: 1, value: "Lakhs" },
              { id: 2, value: "Crores" },
            ],
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total Volume",
            isOptional: true,
            type: "textWithUnit",
            options: [
              { id: 1, value: "KG" },
              { id: 2, value: "MT" },
            ],
            identifier: "bayerricewretailvolume_BayerRICE",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: true,
            type: "text",
            identifier: "wholesaleinpercentageRice_BayerRICE",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Average NDP(in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "averagendp_BayerRICE",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },

      {
        subhead: "Other Companies",
        questions: [
          {
            fieldName: "Total Sales in Other Companies (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "otherRetailRice",
            placeholder: "Input here",
            autopopulateColumn: true,
          },
        ],
      },

      {
        subhead: "Total sales and volume",
        questions: [
          {
            fieldName: "Total sales (in Rs.)",
            isOptional: true,
            type: "text",
            identifier: "totalRetailRice",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            autopopulateColumn: true,
          },
          {
            fieldName: "Total Volume (in KGs)",
            isOptional: true,
            type: "text",
            identifier: "totalRetailRiceVolume",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
    ],
  },
];

export const clearDataArray = [
  "CP_section1_wholesalepercentage",
  "insectiside_totalsale",
  "insectiside_retail",
  "fungiside_totalsale",
  "fungiside_retail",
  "herbiside_totalsale",
  "herbiside_retail",
  "seedtreatment_totalsale",
  "seedtreatment_retail",
  "directfromcompany_totalsale",
  "directfromcompany_retail",
  "fromdistributor_totalsale",
  "fromdistributor_retail",
  "online_totalsale",
  "online_retail",
  "govtbodies_totalsale",
  "govtbodies_retail",
  "others_totalsale",
  "others_retail",
  "company_top_nametop1",
  "company_top_type1",
  "company_top_nametop1total",
  "company_top_namtotalsale1",
  "company_top_nametop2",
  "company_top_type2",
  "company_top_nametop2total",
  "company_top_namtotalsale2",
  "z",
  "company_top_type3",
  "company_top_nametop3total",
  "company_top_namtotalsale3",
  "company_top_nametop4",
  "company_top_type4",
  "company_top_nametop4total",
  "company_top_namtotalsale4",
  "company_top_nametop5",
  "company_top_type5",
  "company_top_nametop5total",
  "company_top_namtotalsale5",
  "bayeruserType",
  "bayertotal2024",
  "bayerwholesale",
  "othercompaniesaggregatedtotal",
  "totalcompaniesaggregated",
  "crop_1",
  "crop_1_total",
  "crop_2",
  "crop_2_total",
  "crop_3",
  "crop_3_total",
  "otherCrops",
  "wholesalebusinessincash",
];

export const TempNonMandatory = [
  "company_top_type3",
  "company_top_nametop3",
  "company_top_nametop3total",
  "company_top_type2",
  "company_top_nametop2",
  "company_top_nametop2total",
  "company_top_type1",
  "company_top_nametop1",
  "company_top_nametop1total",
];

export const clearDataArraySEEDS = [
  "SEE_cornhybrid_totalsale",
  "SEE_cornhybrid_retail",
  "cornhybrid_wholesalepercentage",
  "SEE_paddyybrid_totalsale",
  "SEE_paddyybrid_retail",
  "paddyybrid_wholesalepercentage",
  "SEE_vegetablehybrid_totalsale",
  "SEE_vegetablehybrid_retail",
  "vegetablehybrid_wholesalepercentage",
  "SEE_otherhybrid_totalsale",
  "SEE_otherhybrid_retail",
  "otherhybrid_wholesalepercentage",
  "totalwholesalesseedin2024",
  "corn_company_name1",
  "corn_company_user_type1",
  "corn_company_total_sale1",
  "corn_company_total_volume1",
  "corn_company_wholesale1",
  "corn_company_averagendp1",
  "corn_company_name2",
  "corn_company_user_type2",
  "corn_company_total_sale2",
  "corn_company_total_volume2",
  "corn_company_wholesale2",
  "corn_company_averagendp2",
  "corn_company_name3",
  "corn_company_user_type3",
  "corn_company_total_sale3",
  "corn_company_total_volume3",
  "corn_company_wholesale3",
  "corn_company_averagendp3",
  "corn_bayer_type",
  "corn_bayer_total_sale_2024",
  "corn_bayer_total_sale_mt",
  "corn_bayer_wholesale",
  "corn_bayer_averagendp",
  "corn_other_companies",
  "corn_totalsale",
  "corn_totalvolume",
  "companynamericetop1_RICE1",
  "ricecompanytype1_RICE1",
  "retailsalesrice_RICE1_totalsale",
  "retailvolumerice_RICE1",
  "wholesaleinpercentageRice_RICE1",
  "averagendp1",
  "companynamericetop2",
  "ricecompanytype2_RICE2",
  "retailsalesrice_RICE2_totalsale",
  "retailvolumerice_RICE2",
  "wholesaleinpercentageRice_RICE2",
  "averagendp2",
  "companynamericetop3",
  "ricecompanytype3_RICE3",
  "retailsalesrice_RICE3_totalsale",
  "retailvolumerice_RICE3",
  "wholesaleinpercentageRice_RICE3",
  "averagendp3",
  "bayeruserTyperice_BayerRICE",
  "bayerricetotal2024_BayerRICE_totalsale",
  "bayerricewretailvolume_BayerRICE",
  "wholesaleinpercentageRice_BayerRICE",
  "averagendp_BayerRICE",
  "otherRetailRice",
  "totalRetailRice",
  "totalRetailRiceVolume",
];

export const TempNonMandatorySeeds = [
  "companynamericetop1_RICE1",
  "ricecompanytype1_RICE1",
  "retailsalesrice_RICE1_totalsale",
  "retailvolumerice_RICE1",
  "companynamericetop2",
  "ricecompanytype2_RICE2",
  "retailsalesrice_RICE2_totalsale",
  "retailvolumerice_RICE2",
  "wholesaleinpercentageRice_RICE2",
  "averagendp2",
  "companynamericetop3",
  "ricecompanytype3_RICE3",
  "retailsalesrice_RICE3_totalsale",
  "retailvolumerice_RICE3",
  "wholesaleinpercentageRice_RICE3",
  "averagendp3",
  "bayeruserTyperice_BayerRICE",
  "bayerricetotal2024_BayerRICE_totalsale",
  "bayerricewretailvolume_BayerRICE",
  "wholesaleinpercentageRice_BayerRICE",
  "averagendp_BayerRICE",
  "otherRetailRice",
  "totalRetailRice",
  "totalRetailRiceVolume",
  "corn_company_total_sale2",
  "corn_company_total_sale3",
  "corn_company_total_volume2",
  "corn_company_total_volume3",
  "corn_company_user_type2",
  "corn_company_user_type3",
];

export const TempNonMandatoryPaddy = [
  "companynamericetop1_RICE1",
  "ricecompanytype1_RICE1",
  "retailsalesrice_RICE1_totalsale",
  "retailvolumerice_RICE1",
  "companynamericetop2",
  "ricecompanytype2_RICE2",
  "retailsalesrice_RICE2_totalsale",
  "retailvolumerice_RICE2",
  "wholesaleinpercentageRice_RICE2",
  "averagendp2",
  "companynamericetop3",
  "ricecompanytype3_RICE3",
  "retailsalesrice_RICE3_totalsale",
  "retailvolumerice_RICE3",
  "wholesaleinpercentageRice_RICE3",
  "averagendp3",
  "bayeruserTyperice_BayerRICE",
  "bayerricetotal2024_BayerRICE_totalsale",
  "bayerricewretailvolume_BayerRICE",
  "wholesaleinpercentageRice_BayerRICE",
  "averagendp_BayerRICE",
  "otherRetailRice",
  "totalRetailRice",
  "totalRetailRiceVolume",
]

export const clearDataArraySEEDSCorn = [
  "corn_company_name1",
  "corn_company_user_type1",
  "corn_company_total_sale1",
  "corn_company_total_volume1",
  "corn_company_wholesale1",
  "corn_company_averagendp1",
  "corn_company_name2",
  "corn_company_user_type2",
  "corn_company_total_sale2",
  "corn_company_total_volume2",
  "corn_company_wholesale2",
  "corn_company_averagendp2",
  "corn_company_name3",
  "corn_company_user_type3",
  "corn_company_total_sale3",
  "corn_company_total_volume3",
  "corn_company_wholesale3",
  "corn_company_averagendp3",
  "corn_bayer_type",
  "corn_bayer_total_sale_2024",
  "corn_bayer_total_sale_mt",
  "corn_bayer_wholesale",
  "corn_bayer_averagendp",
  "corn_other_companies",
  "corn_totalsale",
  "corn_totalvolume",
]

export const clearDataArraySEEDSRice = [
  "companynamericetop1_RICE1",
  "ricecompanytype1_RICE1",
  "retailsalesrice_RICE1_totalsale",
  "retailvolumerice_RICE1",
  "wholesaleinpercentageRice_RICE1",
  "averagendp1",
  "companynamericetop2",
  "ricecompanytype2_RICE2",
  "retailsalesrice_RICE2_totalsale",
  "retailvolumerice_RICE2",
  "wholesaleinpercentageRice_RICE2",
  "averagendp2",
  "companynamericetop3",
  "ricecompanytype3_RICE3",
  "retailsalesrice_RICE3_totalsale",
  "retailvolumerice_RICE3",
  "wholesaleinpercentageRice_RICE3",
  "averagendp3",
  "bayeruserTyperice_BayerRICE",
  "bayerricetotal2024_BayerRICE_totalsale",
  "bayerricewretailvolume_BayerRICE",
  "wholesaleinpercentageRice_BayerRICE",
  "averagendp_BayerRICE",
  "otherRetailRice",
  "totalRetailRice",
  "totalRetailRiceVolume",
]